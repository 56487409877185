<template>
    <form @submit.prevent="login">
        <b-form-group :label="$t('phrases.email_address')">
            <b-input-group>
                <b-input-group-prepend>
                    <b-input-group-text>
                        <font-awesome-icon icon="fa-solid fa-user" />
                    </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input v-model="user.email" />
                <div class="invalid-feedback" :class="{'d-block': error === 'Unauthorized'}">
                    {{ $t('auth.failed') }}
                </div>
            </b-input-group>
        </b-form-group>
        <b-form-group :label="$t('phrases.password')">
            <b-input-group>
                <b-input-group-prepend>
                    <b-input-group-text>
                        <font-awesome-icon icon="fa-solid fa-lock" />
                    </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="password" v-model="user.password" />
            </b-input-group>
        </b-form-group>
        <b-form-group class="mb-0">
            <b-button type="submit" variant="primary" block :disabled=" ! hasInput()">{{ $t('phrases.login') }}</b-button>
        </b-form-group>
    </form>
</template>
<script>
export default {
    name: 'LoginForm',
    data: () => ({
        error: '',
        user: {
            email: '',
            password: ''
        }
    }),
    methods: {
        hasInput() {
            const user = this.user
            return user.email !== '' && user.password !== ''
        },
        login() {
            let user = this.user

            this
                .axios
                .post('/api/7pyn5wd/authentication/login', {
                    email: user.email,
                    password: user.password
                })
                .then((response) => {
                    if (response.data.error) {
                        this.error = response.data.error
                    } else {
                        this.error = ''
                        this.$store.dispatch('login', response.data.token)
                    }
                })
                .catch(() => {
                    this.$store.dispatch('logout')
                })
        }
    }
}
</script>
