<template>
    <b-container fluid>
        <h4 class="mt-4 mb-4">{{ $t('phrases.substances') }}</h4>
        <b-row class="mb-4">
            <b-col sm="7">
                <b-button-group>
                    <b-button variant="primary" @click="create()">{{ $t('phrases.create') }}</b-button>
                    <b-button variant="secondary" @click="fetch()">{{ $t('phrases.refresh') }}</b-button>
                </b-button-group>
            </b-col>
            <b-col sm="5">
                <search collection="substances" />
            </b-col>
        </b-row>
        <b-overlay :show="busy">
            <div class="bg-white shadow-sm p-4">
                <div class="table-responsive">
                    <table class="table table-hover table-bordered no-wrap">
                        <thead class="bg-light text-muted">
                            <tr>
                                <th width="10%">
                                    <sortable-link href="/substances" collection="substances" column="substances.id">{{ $t('phrases.id') }}</sortable-link>
                                </th>
                                <th width="30%">
                                    <sortable-link href="/substances" collection="substances" column="substances.name">{{ $t('phrases.name') }}</sortable-link>
                                </th>
                                <th width="15%">
                                    <sortable-link href="/substances" collection="substances" column="substances.active">{{ $t('phrases.active') }}</sortable-link>
                                </th>
                                <th width="15%">
                                    {{ $t('phrases.translation_count') }}
                                </th>
                                <th class="text-right">
                                    {{ $t('phrases.actions') }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(substance, i) in substances" :key="i">
                                <td>
                                    {{ substance.id }}
                                </td>
                                <td>
                                    {{ substance.name }}
                                </td>
                                <td>
                                    <bool-status :value="substance.active" />
                                </td>
                                <td>
                                    {{ substance.translations_count }}
                                </td>
                                <td class="text-right">
                                    <a href="/substances" @click.prevent="edit(substance)">{{ $t('phrases.edit') }}</a>
                                    <a href="/substances" class="text-danger ml-2" @click.prevent="destroy(substance)">{{ $t('phrases.delete') }}</a>
                                </td>
                            </tr>
                            <tr v-if="substances.length === 0">
                                <td colspan="5" class="text-muted">
                                    {{ $t('messages.no_substances_to_display') }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <b-row>
                    <b-col sm="6">
                        <pagination collection="substances" :meta="meta" class="mt-3" />
                    </b-col>
                    <b-col sm="6">
                        <div class="mt-3 text-left text-sm-right">
                            {{ info() }}
                        </div>
                    </b-col>
                </b-row>
            </div>
        </b-overlay>
        <b-modal id="modal-substance-create" :title="$t('phrases.create_substance')" @ok="store">
            <substance-create-form ref="form-substance-create" />
        </b-modal>
        <b-modal id="modal-substance-edit" :title="$t('phrases.edit_substance')" @ok="update">
            <substance-edit-form :edited-substance="selectedSubstance" ref="form-substance-edit" />
        </b-modal>
    </b-container>
</template>
<script>
import dataTableMixin from '@/mixins/data-table'
import BoolStatus from '@/components/BoolStatus'
import Pagination from '@/components/Pagination'
import Search from '@/components/Search'
import SortableLink from '@/components/SortableLink'
import SubstanceCreateForm from '@/components/SubstanceCreateForm'
import SubstanceEditForm from '@/components/SubstanceEditForm'

export default {
    name: 'SubstanceList',
    mixins: [ dataTableMixin ],
    components: {
        BoolStatus,
        Pagination,
        Search,
        SortableLink,
        SubstanceCreateForm,
        SubstanceEditForm
    },
    data: () => ({
        selectedSubstance: null
    }),
    computed: {
        substances() {
            return this._.get(this, 'resource.data', [])
        }
    },
    methods: {
        create() {
            this.$bvModal.show('modal-substance-create')
        },
        destroy(substance) {
            let name = this._.get(substance, 'name')
            let model = this.$t('phrases.substance')
            let message = this.$t('messages.confirm_delete_name', { name })

            if (confirm(message)) {
                this
                    .axios
                    .delete(`/api/7pyn5wd/substances/${substance.id}`)
                    .then((response) => {
                        if (response.status === 204) {
                            message = this.$t('messages.model_deleted_text', { model })

                            this.toast(message, 'info')
                            this.fetch()
                        }
                    })
                    .catch(() => {
                        message = this.$t('messages.model_name_delete_error_text', { model, name })
                        this.toast(message, 'danger')
                    })
            }
        },
        edit(substance) {
            this.selectedSubstance = substance
            this.$bvModal.show('modal-substance-edit')
        },
        listen() {
            this.$root.$off('paginate-substances')
            this.$root.$off('search-substances')
            this.$root.$off('sort-substances')
            this.$root.$off('substance-created')
            this.$root.$off('substance-updated')

            // --------------------------------------------------
            // Pagination
            // --------------------------------------------------
            this.$root.$on('paginate-substances', (page) => {
                this.page = page
            })

            // --------------------------------------------------
            // Search
            // --------------------------------------------------
            this.$root.$on('search-substances', (phrase) => {
                this.phrase = phrase
            })

            // --------------------------------------------------
            // Sort
            // --------------------------------------------------
            this.$root.$on('sort-substances', ({ column, direction }) => {
                this.sort = { column, direction }
            })

            // --------------------------------------------------
            // Substance events
            // --------------------------------------------------
            this.$root.$on('substance-created', () => {
                this.$bvModal.hide('modal-substance-create')
                this.fetch()

                let model = this.$t('phrases.substance')
                let message = this.$t('messages.model_created_text', { model })

                this.toast(message, 'info')
            })

            this.$root.$on('substance-updated', () => {
                this.$bvModal.hide('modal-substance-edit')
                this.fetch()

                let model = this.$t('phrases.substance')
                let message = this.$t('messages.model_updated_text', { model })

                this.toast(message, 'info')
            })
        },
        store(event) {
            event.preventDefault()
            this.$refs['form-substance-create'].store()

            return
        },
        update(event) {
            event.preventDefault()
            this.$refs['form-substance-edit'].update()

            return
        }
    },
    mounted() {
        this.fetch()
        this.listen()
    }
}
</script>
