<template>
    <form>
        <b-form-group>
            <template v-slot:label>
                {{ $t('phrases.title') }}
                <span class="text-danger">*</span>
            </template>
            <b-form-input maxlength="255" v-model="event.title" />
            <div class="invalid-feedback d-block" v-if="error('title') !== null">
                {{ error('title') }}
            </div>
        </b-form-group>
        <b-form-group :label="$t('phrases.website')">
            <b-form-input maxlength="255" v-model="event.website" />
            <div class="invalid-feedback d-block" v-if="error('website') !== null">
                {{ error('website') }}
            </div>
        </b-form-group>
        <b-form-group :label="$t('phrases.phone')">
            <b-form-input maxlength="20" v-model="event.phone" />
            <div class="invalid-feedback d-block" v-if="error('phone') !== null">
                {{ error('phone') }}
            </div>
        </b-form-group>
        <b-form-group>
            <template v-slot:label>
                {{ $t('phrases.email') }}
                <span class="text-danger">*</span>
            </template>
            <b-form-input maxlength="255" v-model="event.email" />
            <div class="invalid-feedback d-block" v-if="error('email') !== null">
                {{ error('email') }}
            </div>
        </b-form-group>
        <b-form-group :label="$t('phrases.address')">
            <b-form-input :value="addressLine(address, ',', ['address', 'address_2', 'city', 'state', 'zip', 'country'])" readonly />
        </b-form-group>
        <b-form-group>
            <template v-slot:label>
                {{ $t('phrases.start_date') }}
                <span class="text-danger">*</span>
            </template>
            <b-form-datepicker
                :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                :reset-button="true"
                reset-button-variant="secondary"
                v-model="event.start_date" />
            <div class="invalid-feedback d-block" v-if="error('start_date') !== null">
                {{ error('start_date') }}
            </div>
        </b-form-group>
        <b-form-group :label="$t('phrases.end_date')">
            <b-form-datepicker
                :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                :reset-button="true"
                reset-button-variant="secondary"
                v-model="event.end_date" />
            <div class="invalid-feedback d-block" v-if="error('end_date') !== null">
                {{ error('end_date') }}
            </div>
        </b-form-group>
        <b-form-group :label="$t('phrases.start_time')">
            <b-form-timepicker :hour12="false" reset-button v-model="event.start_time" />
        </b-form-group>
        <b-form-group :label="$t('phrases.end_time')">
            <b-form-timepicker :hour12="false" reset-button v-model="event.end_time" />
        </b-form-group>
        <b-form-group :label="$t('phrases.description')">
            <b-form-textarea rows="10" v-model="event.description" />
            <div class="invalid-feedback d-block" v-if="error('description') !== null">
                {{ error('description') }}
            </div>
        </b-form-group>
        <b-form-group>
            <template v-slot:label>
                {{ $t('phrases.status') }}
                <span class="text-danger">*</span>
            </template>
            <select class="custom-select" v-model="event.status">
                <option :value="null"></option>
                <option v-for="(name, id, i) in eventStatuses()" :key="i" :value="id">{{ name }}</option>
            </select>
            <div class="invalid-feedback d-block" v-if="error('status') !== null">
                {{ error('status') }}
            </div>
        </b-form-group>
    </form>
</template>
<script>
import formErrorsMixin from '@/mixins/form-errors'

export default {
    name: 'EventEditForm',
    mixins: [ formErrorsMixin ],
    props: {
        editedEvent: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        event: {
            id: null,
            title: '',
            website: '',
            phone: '',
            email: '',
            start_date: null,
            end_date: null,
            start_time: null,
            end_time: null,
            description: '',
            status: null
        }
    }),
    computed: {
        address() {
            const event = this.editedEvent
            return this.addressableAddress(event)
        }
    },
    methods: {
        populate() {
            const editedEvent = this.editedEvent
            const event = this.event

            if ( ! this._.isEmpty(editedEvent)) {
                this._.forOwn(editedEvent, (value, key) => {
                    if (key in event) {
                        event[key] = value
                    }
                })

                this.event = event
            }
        },
        update() {
            let event = this.event

            this
                .axios
                .put(`/api/7pyn5wd/events/update/${event.id}`, event)
                .then((response) => {
                    this.setErrors(response)
                    event = this._.get(response, 'data.data')

                    if ( ! this._.isEmpty(event)) {
                        this.$root.$emit('event-updated', event)
                    }
                })
                .catch((error) => {
                    this.setErrors(error.response)
                })
        }
    },
    mounted() {
        this.populate()
    }
}
</script>
