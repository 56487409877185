<template>
    <form>
        <b-form-group>
            <template v-slot:label>
                {{ $t('phrases.first_name') }}
                <span class="text-danger">*</span>
            </template>
            <b-form-input maxlength="100" v-model="user.first_name" />
            <div class="invalid-feedback d-block" v-if="error('first_name') !== null">
                {{ error('first_name') }}
            </div>
        </b-form-group>
        <b-form-group>
            <template v-slot:label>
                {{ $t('phrases.last_name') }}
                <span class="text-danger">*</span>
            </template>
            <b-form-input maxlength="100" v-model="user.last_name" />
            <div class="invalid-feedback d-block" v-if="error('last_name') !== null">
                {{ error('last_name') }}
            </div>
        </b-form-group>
        <b-form-group>
            <template v-slot:label>
                {{ $t('phrases.email') }}
                <span class="text-danger">*</span>
            </template>
            <b-form-input maxlength="255" v-model="user.email" />
            <div class="invalid-feedback d-block" v-if="error('email') !== null">
                {{ error('email') }}
            </div>
        </b-form-group>
        <b-form-group :label="$t('phrases.phone')">
            <b-form-input maxlength="20" v-model="user.phone" />
            <div class="invalid-feedback d-block" v-if="error('phone') !== null">
                {{ error('phone') }}
            </div>
        </b-form-group>
        <b-form-group :label="$t('phrases.password')">
            <b-input-group>
                <b-form-input :type="passwordInputType" maxlength="40" v-model="user.password" />
                <b-input-group-append>
                    <b-button variant="secondary" @click="togglePasswordInputType()">
                        <font-awesome-icon icon="fa-solid fa-eye" />
                    </b-button>
                </b-input-group-append>
            </b-input-group>
            <password :strength-meter-only="true" v-model="user.password" />
            <div class="invalid-feedback d-block" v-if="error('password') !== null">
                {{ error('password') }}
            </div>
        </b-form-group>
        <b-form-group>
            <div class="custom-control custom-checkbox">
                <input type="checkbox" id="input-active" class="custom-control-input" v-model="user.active" :true-value="1" :false-value="0">
                <label for="input-active" class="custom-control-label">{{ $t('phrases.active') }}</label>
            </div>
        </b-form-group>
    </form>
</template>
<script>
import formErrorsMixin from '@/mixins/form-errors'
import formPasswordMixin from '@/mixins/form-password'
import Password from 'vue-password-strength-meter'

export default {
    name: 'UserEditForm',
    mixins: [ formErrorsMixin, formPasswordMixin ],
    components: { Password },
    props: {
        editedUser: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        user: {
            id: null,
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            password: '',
            active: 0
        }
    }),
    methods: {
        populate() {
            const editedUser = this.editedUser
            const user = this.user

            if ( ! this._.isEmpty(editedUser)) {
                this._.forOwn(editedUser, (value, key) => {
                    if (key in user) {
                        user[key] = value
                    }
                })

                this.user = user
            }
        },
        update() {
            let user = this.user

            this
                .axios
                .put(`/api/7pyn5wd/users/update/${user.id}`, user)
                .then((response) => {
                    this.setErrors(response)
                    user = this._.get(response, 'data.data')

                    if ( ! this._.isEmpty(user)) {
                        this.$root.$emit('user-updated', user)
                    }
                })
                .catch((error) => {
                    this.setErrors(error.response)
                })
        }
    },
    mounted() {
        this.populate()
    }
}
</script>
