<template>
    <table class="table table-bordered">
        <thead>
            <tr>
                <th class="bg-light" width="25%">{{ $t('phrases.attribute') }}</th>
                <th>{{ $t('phrases.value') }}</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="bg-light">{{ $t('phrases.id') }}</td>
                <td>{{ event.id }}</td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.title') }}</td>
                <td>{{ event.title }}</td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.website') }}</td>
                <td>
                    <a :href="event.website" target="_blank" v-if="event.website">{{ event.website }}</a>
                    <span v-else> - </span>
                </td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.phone') }}</td>
                <td>
                    <span v-if="event.phone">{{ event.phone }}</span>
                    <span v-else> - </span>
                </td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.email') }}</td>
                <td>
                    <a :href="'mailto:' + event.email" v-if="event.email">{{ event.email }}</a>
                    <span v-else> - </span>
                </td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.address') }}</td>
                <td>
                    <span v-if="address">{{ addressLine(address, ',', ['address', 'address_2', 'city', 'zip', 'state', 'country']) }}</span>
                    <span v-else> - </span>
                </td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.address_coordinates') }}</td>
                <td>
                    <code v-if="address && address.latitude && address.longitude">
                        &lt;{{ address.latitude }}, {{ address.longitude }}&gt;
                    </code>
                    <span v-else> - </span>
                </td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.start_date') }}</td>
                <td>
                    <span v-if="event.start_date">{{ event.start_date }}</span>
                    <span v-else> - </span>
                </td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.end_date') }}</td>
                <td>
                    <span v-if="event.end_date">{{ event.end_date }}</span>
                    <span v-else> - </span>
                </td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.start_time') }}</td>
                <td>
                    <span v-if="event.start_time">{{ event.start_time }}</span>
                    <span v-else> - </span>
                </td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.end_time') }}</td>
                <td>
                    <span v-if="event.end_time">{{ event.end_time }}</span>
                    <span v-else> - </span>
                </td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.description') }}</td>
                <td>
                    <span v-if="event.description" v-html="nl2br(event.description)"></span>
                    <span v-else> - </span>
                </td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.status') }}</td>
                <td>
                    <event-status :status="event.status" />
                </td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.eventable_type') }}</td>
                <td>
                    <code>{{ event.eventable_type }}</code>
                </td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.eventable') }}</td>
                <td>
                    {{ event.eventable.name }}
                </td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.created_at') }}</td>
                <td>{{ event.created_at }}</td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.updated_at') }}</td>
                <td>{{ event.updated_at }}</td>
            </tr>
        </tbody>
    </table>
</template>
<script>
import EventStatus from '@/components/EventStatus'

export default {
    name: 'EventView',
    components: {
        EventStatus
    },
    props: {
        event: {
            type: Object,
            required: true
        }
    },
    computed: {
        address() {
            const event = this.event
            return this.addressableAddress(event)
        }
    }
}
</script>
