export default {
    props: {
        collection: {
            type: String,
            required: true
        },
        source: {
            type: String,
            required: true
        },
        query: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
            resource: {},
            phrase: '',
            page: 1,
            sort: {},
            busy: false
        }
    },
    computed: {
        meta() {
            return this._.get(this, 'resource.meta', {})
        }
    },
    methods: {
        fetch(params = null) {
            this.busy = true

            if (this._.isNil(params)) {
                params = this.params();
            }

            this
                .axios
                .get(this.source, { params })
                .then((response) => {
                    this.busy = false
                    this.resource = response.data
                })
        },
        info() {
            let info = ''
            let meta = this.meta

            if (meta.total > 0) {
                info = this.$t('messages.pagination_meta_info', {
                    from: meta.from,
                    to: meta.to,
                    total: meta.total
                })
            }

            return info
        },
        params() {
            let params = {}
            let phrase = this.phrase
            let page = this.page
            let sort = this.sort

            if (phrase) {
                params['search'] = phrase
            }

            if (page) {
                params['page'] = page
            }

            if (this._.isObject(sort)) {
                let { column, direction } = sort

                if (column) {
                    params['order_by'] = column
                }

                if (direction) {
                    params['direction'] = direction
                }
            }

            let query = this.query

            if ( ! this._.isNil(query)) {
                params = this._.merge(params, query)
            }

            return params
        }
    },
    watch: {
        phrase: {
            handler() {
                let params = this._.omit(this.params(), 'page')
                this.fetch(params)
            }
        },
        page: {
            handler() {
                this.fetch(this.params())
            }
        },
        sort: {
            handler() {
                let params = this._.omit(this.params(), 'page')
                let { column, direction } = this.sort

                this.fetch(params)

                if (column && direction) {
                    let collection = this.collection
                    let name = ['sorted', collection].join('-')

                    this.$root.$emit(name, { column, direction })
                }
            },
            deep: true
        }
    }
}
