export default {
    methods: {
        languageCollection() {
            return this.$store.getters['language_collection']
        },
        refreshLanguageCollection() {
            this.$store.dispatch('languageCollection')
        }
    }
}
