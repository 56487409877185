export default {
    data: () => ({
        errors: {}
    }),
    methods: {
        clearErrors() {
            this.errors = {}
        },
        error(attribute) {
            return this._.get(this.errors, attribute, null)
        },
        setErrors(response) {
            this.clearErrors()
            let errors = this._.get(response, 'data.errors', {})

            if ( ! this._.isEmpty(errors)) {
                for (let property in errors) {
                    if (errors[property][0]) {
                        this.errors[property] = errors[property][0]
                    }
                }
            }
        }
    }
}
