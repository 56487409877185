export default {
    methods: {
        filesize(model) {
            const size = this._.get(model, 'size', 0)
            return Math.round(size / 1024)
        },
        imageIsCover(image) {
            return parseInt(image.cover) === 1
        },
        imageIsPrimary(image) {
            return parseInt(image.primary) === 1
        }
    }
}
