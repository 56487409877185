<template>
    <span class="logo">
        <font-awesome-icon icon="fa-solid fa-carrot" />
    </span>
</template>
<script>
export default {
    name: 'Logo'
}
</script>
