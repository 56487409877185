<template>
    <table class="table table-bordered">
        <thead>
            <tr>
                <th class="bg-light" width="25%">{{ $t('phrases.attribute') }}</th>
                <th>{{ $t('phrases.value') }}</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="bg-light">{{ $t('phrases.id') }}</td>
                <td>{{ city.id }}</td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.name') }}</td>
                <td>{{ city.name }}</td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.ascii_name') }}</td>
                <td>
                    <span v-if="city.name_ascii">{{ city.name_ascii }}</span>
                    <span v-else> - </span>
                </td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.zip_codes') }}</td>
                <td>
                    <span v-if="city.zips">{{ city.zips }}</span>
                    <span v-else> - </span>
                </td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.population') }}</td>
                <td>
                    <span v-if="city.population">{{ city.population }}</span>
                    <span v-else> - </span>
                </td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.latitude') }}</td>
                <td>
                    <code v-if="city.latitude">{{ city.latitude }}</code>
                    <span v-else> - </span>
                </td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.longitude') }}</td>
                <td>
                    <code v-if="city.longitude">{{ city.longitude }}</code>
                    <span v-else> - </span>
                </td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.timezone') }}</td>
                <td>
                    <code v-if="city.timezone">{{ city.timezone }}</code>
                    <span v-else> - </span>
                </td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.state') }}</td>
                <td>
                    <span v-if="city.state_id">{{ city.state.name }}</span>
                    <span v-else> - </span>
                </td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.country') }}</td>
                <td>
                    <span v-if="city.state.country_id">{{ city.state.country.name }}</span>
                    <span v-else> - </span>
                </td>
            </tr>
        </tbody>
    </table>
</template>
<script>
export default {
    name: 'CityView',
    props: {
        city: {
            type: Object,
            required: true
        }
    }
}
</script>
