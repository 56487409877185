<template>
    <b-container fluid>
        <h4 class="mt-4 mb-4">{{ $t('phrases.markets') }}</h4>
        <b-row class="mb-4">
            <b-col sm="7">
                <b-button-group>
                    <b-button variant="secondary" @click="fetch()">{{ $t('phrases.refresh') }}</b-button>
                </b-button-group>
            </b-col>
            <b-col sm="5">
                <search collection="markets" />
            </b-col>
        </b-row>
        <b-overlay :show="busy">
            <div class="bg-white shadow-sm p-4">
                <div class="table-responsive">
                    <table class="table table-hover table-bordered no-wrap">
                        <thead class="bg-light text-muted">
                            <tr>
                                <th width="8%">
                                    <sortable-link href="/markets" collection="markets" column="markets.id">{{ $t('phrases.id') }}</sortable-link>
                                </th>
                                <th width="23%">
                                    <sortable-link href="/markets" collection="markets" column="markets.name">{{ $t('phrases.name') }}</sortable-link>
                                </th>
                                <th width="23%">
                                    <sortable-link href="/markets" collection="markets" column="markets.user_id">{{ $t('phrases.owner') }}</sortable-link>
                                </th>
                                <th width="15%">
                                    <sortable-link href="/markets" collection="markets" column="markets.active">{{ $t('phrases.active') }}</sortable-link>
                                </th>
                                <th width="15%">
                                    <sortable-link href="/markets" collection="markets" column="markets.promote">{{ $t('phrases.promote') }}</sortable-link>
                                </th>
                                <th class="text-right">
                                    {{ $t('phrases.actions') }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(market, i) in markets" :key="i">
                                <td>
                                    {{ market.id }}
                                </td>
                                <td>
                                    {{ market.name }}
                                </td>
                                <td>
                                    {{ fullName(market.user, false) }}
                                </td>
                                <td>
                                    <bool-status :value="market.active" />
                                </td>
                                <td>
                                    <bool-status :value="market.promote" />
                                </td>
                                <td class="text-right">
                                    <a href="/markets" @click.prevent="activate(market)" v-if="!market.active">{{ $t('phrases.activate') }}</a>
                                    <a href="/markets" class="ml-2" @click.prevent="edit(market)">{{ $t('phrases.edit') }}</a>
                                    <a href="/markets" class="ml-2" @click.prevent="show(market)">{{ $t('phrases.view') }}</a>
                                </td>
                            </tr>
                            <tr v-if="markets.length === 0">
                                <td colspan="6" class="text-muted">
                                    {{ $t('messages.no_markets_to_display') }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <b-row>
                    <b-col sm="6">
                        <pagination collection="markets" :meta="meta" class="mt-3" />
                    </b-col>
                    <b-col sm="6">
                        <div class="mt-3 text-left text-sm-right">
                            {{ info() }}
                        </div>
                    </b-col>
                </b-row>
            </div>
        </b-overlay>
        <b-modal id="modal-market-edit" :title="$t('phrases.edit_market')" @ok="update">
            <market-edit-form :edited-market="selectedMarket" ref="form-market-edit" />
        </b-modal>
        <b-modal id="modal-market-view" size="xl" :title="$t('phrases.market_details')" hide-footer>
            <market-view :market="selectedMarket" />
        </b-modal>
    </b-container>
</template>
<script>
import dataTableMixin from '@/mixins/data-table'
import BoolStatus from '@/components/BoolStatus'
import MarketEditForm from '@/components/MarketEditForm'
import MarketView from '@/components/MarketView'
import Pagination from '@/components/Pagination'
import Search from '@/components/Search'
import SortableLink from '@/components/SortableLink'

export default {
    name: 'MarketList',
    mixins: [ dataTableMixin ],
    components: {
        BoolStatus,
        MarketEditForm,
        MarketView,
        Pagination,
        Search,
        SortableLink
    },
    data: () => ({
        selectedMarket: null
    }),
    computed: {
        markets() {
            return this._.get(this, 'resource.data', [])
        }
    },
    methods: {
        activate(market) {
            let model = this.$t('phrases.market').toLowerCase()
            let message = this.$t('messages.confirm_activate_model', { model })

            if (confirm(message)) {
                model = this.$t('phrases.market')

                this
                    .axios
                    .put(`/api/7pyn5wd/markets/activate/${market.id}`)
                    .then(() => {
                        message = this.$t('messages.model_updated_text', { model })

                        this.toast(message, 'info')
                        this.fetch()
                    })
            }
        },
        edit(market) {
            this.selectedMarket = market
            this.$bvModal.show('modal-market-edit')
        },
        listen() {
            this.$root.$off('paginate-markets')
            this.$root.$off('search-markets')
            this.$root.$off('sort-markets')
            this.$root.$off('market-updated')

            // --------------------------------------------------
            // Pagination
            // --------------------------------------------------
            this.$root.$on('paginate-markets', (page) => {
                this.page = page
            })

            // --------------------------------------------------
            // Search
            // --------------------------------------------------
            this.$root.$on('search-markets', (phrase) => {
                this.phrase = phrase
            })

            // --------------------------------------------------
            // Sort
            // --------------------------------------------------
            this.$root.$on('sort-markets', ({ column, direction }) => {
                this.sort = { column, direction }
            })

            // --------------------------------------------------
            // Market events
            // --------------------------------------------------
            this.$root.$on('market-updated', () => {
                this.$bvModal.hide('modal-market-edit')
                this.fetch()

                let model = this.$t('phrases.market')
                let message = this.$t('messages.model_updated_text', { model })

                this.toast(message, 'info')
            })
        },
        show(market) {
            this.selectedMarket = market
            this.$bvModal.show('modal-market-view')
        },
        update(event) {
            event.preventDefault()
            this.$refs['form-market-edit'].update()

            return
        }
    },
    mounted() {
        this.fetch()
        this.listen()
    }
}
</script>
