import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

// Router
let router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/login',
            name: 'login',
            component: require('../components/Login').default,
            meta: { auth: false }
        },
        {
            path: '/admins',
            name: 'admins',
            component: require('../components/AdminList').default,
            props: {
                collection: 'admins',
                source: '/api/7pyn5wd/admins/index'
            },
            meta: { auth: true }
        },
        {
            path: '/categories',
            name: 'categories',
            component: require('../components/CategoryList').default,
            props: {
                collection: 'categories',
                source: '/api/7pyn5wd/categories/index'
            },
            meta: { auth: true }
        },
        {
            path: '/cities',
            name: 'cities',
            component: require('../components/CityList').default,
            props: {
                collection: 'cities',
                source: '/api/7pyn5wd/cities/index'
            },
            meta: { auth: true }
        },
        {
            path: '/comments',
            name: 'comments',
            component: require('../components/CommentList.vue').default,
            props: {
                collection: 'comments',
                source: '/api/7pyn5wd/comments/index'
            },
            meta: { auth: true }
        },
        {
            path: '/events',
            name: 'events',
            component: require('../components/EventList').default,
            props: {
                collection: 'events',
                source: '/api/7pyn5wd/events/index'
            },
            meta: { auth: true }
        },
        {
            path: '/farms',
            name: 'farms',
            component: require('../components/FarmList').default,
            props: {
                collection: 'farms',
                source: '/api/7pyn5wd/farms/index'
            },
            meta: { auth: true }
        },
        {
            path: '/images',
            name: 'images',
            component: require('../components/ImageList').default,
            props: {
                collection: 'images',
                source: '/api/7pyn5wd/images/index'
            },
            meta: { auth: true }
        },
        {
            path: '/inventory',
            name: 'inventory',
            component: require('../components/InventoryList').default,
            props: {
                collection: 'inventory',
                source: '/api/7pyn5wd/inventory/index'
            },
            meta: { auth: true }
        },
        {
            path: '/languages',
            name: 'languages',
            component: require('../components/LanguageList').default,
            props: {
                collection: 'languages',
                source: '/api/7pyn5wd/languages/index'
            },
            meta: { auth: true }
        },
        {
            path: '/markets',
            name: 'markets',
            component: require('../components/MarketList').default,
            props: {
                collection: 'markets',
                source: '/api/7pyn5wd/markets/index'
            },
            meta: { auth: true }
        },
        {
            path: '/messages',
            name: 'messages',
            component: require('../components/MessageList').default,
            props: {
                collection: 'messages',
                source: '/api/7pyn5wd/messages/index'
            },
            meta: { auth: true }
        },
        {
            path: '/reviews',
            name: 'reviews',
            component: require('../components/ReviewList').default,
            props: {
                collection: 'reviews',
                source: '/api/7pyn5wd/reviews/index'
            },
            meta: { auth: true }
        },
        {
            path: '/substances',
            name: 'substances',
            component: require('../components/SubstanceList').default,
            props: {
                collection: 'substances',
                source: '/api/7pyn5wd/substances/index'
            },
            meta: { auth: true }
        },
        {
            path: '/translations',
            name: 'translations',
            component: require('../components/TranslationList').default,
            props: {
                collection: 'translations',
                source: '/api/7pyn5wd/translations/index'
            },
            meta: { auth: true }
        },
        {
            path: '/users',
            name: 'users',
            component: require('../components/UserList').default,
            props: {
                collection: 'users',
                source: '/api/7pyn5wd/users/index'
            },
            meta: { auth: true }
        },
        {
            path: '*',
            redirect: '/login'
        }
    ]
})

router.beforeEach((to, from, next) => {

    let token = store.getters['auth_token']

    // Route requires authentication
    if (to.meta.auth && router.app._.isNil(token)) {
        return next('/login')
    }

    return next()
})

export default router
