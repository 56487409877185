<template>
    <form class="mt-3 mt-sm-0" autocomplete="off" @submit.prevent="search">
        <b-input-group>
            <b-form-input :placeholder="$t('phrases.search')" @keydown="isTyping = true" v-model="phrase" />
            <b-input-group-append>
                <b-button type="submit" variant="secondary">{{ $t('phrases.go') }}</b-button>
                <b-button variant="light" @click="clear()" v-if="phrase">{{ $t('phrases.clear_search') }}</b-button>
            </b-input-group-append>
        </b-input-group>
    </form>
</template>
<script>
export default {
    name: 'Search',
    props: {
        collection: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            phrase: '',
            isTyping: false,
            timeout: null
        }
    },
    watch: {
        phrase: {
            handler() {
                if (this.timeout) {
                    clearTimeout(this.timeout)
                    this.timeout = null
                }

                this.timeout = setTimeout(() => {
                    this.isTyping = false
                }, 400)
            }
        },
        isTyping: {
            handler() {
                if ( ! this.isTyping) {
                    this.search()
                }
            }
        }
    },
    methods: {
        clear() {
            this.phrase = ''
            this.search()
        },
        search() {
            let collection = this.collection
            let phrase = this.phrase
            let name = ['search', collection].join('-')

            this.$root.$emit(name, phrase)
        }
    }
}
</script>
