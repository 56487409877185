export default {
    methods: {
        translatableTypes() {
            return this._.get(this.translationMeta(), 'types')
        },
        translationMeta() {
            return this.$store.getters['translation_meta']
        }
    }
}
