import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth'
import category from './modules/category'
import city from './modules/city'
import country from './modules/country'
import event from './modules/event'
import language from './modules/language'
import inventory from './modules/inventory'
import substance from './modules/substance'
import translation from './modules/translation'
import unit from './modules/unit'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        auth,
        category,
        city,
        country,
        event,
        language,
        inventory,
        substance,
        translation,
        unit
    }
})
