export default {
    state: {
        meta: {}
    },
    mutations: {
        eventMeta(state, meta) {
            state.meta = meta
        }
    },
    getters: {
        'event_meta': state => state.meta
    },
    actions: {
        async eventMeta({ commit }) {
            return this
                ._vm
                .axios
                .get('/api/7pyn5wd/events/meta')
                .then((response) => {
                    commit('eventMeta', response.data)
                })
                .catch(() => {
                    commit('eventMeta', {})
                })
        }
    }
}
