export default {
    methods: {
        inventoryCollection() {
            return this.$store.getters['inventory_collection']
        },
        refreshInventoryCollection() {
            this.$store.dispatch('inventoryCollection')
        }
    }
}
