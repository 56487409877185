<template>
    <form>
        <b-form-group>
            <template v-slot:label>
                {{ $t('phrases.name') }}
                <span class="text-danger">*</span>
            </template>
            <b-form-input maxlength="255" v-model="inventory.name" />
            <div class="invalid-feedback d-block" v-if="error('name') !== null">
                {{ error('name') }}
            </div>
        </b-form-group>
        <b-form-group>
            <template v-slot:label>
                {{ $t('phrases.category') }}
                <span class="text-danger">*</span>
            </template>
            <select class="custom-select" v-model="inventory.category_id">
                <option :value="null"></option>
                <option v-for="(category, i) in categoryCollection()" :key="i" :value="category.id">{{ category.name }}</option>
            </select>
            <div class="invalid-feedback d-block" v-if="error('category_id') !== null">
                {{ error('category_id') }}
            </div>
        </b-form-group>
        <b-form-group :label="$t('phrases.photo')">
            <b-form-file accept="image/*" v-model="photo"></b-form-file>
            <div class="invalid-feedback d-block" v-if="error('photo') !== null">
                {{ error('photo') }}
            </div>
        </b-form-group>
        <b-form-group :label="$t('phrases.availability')">
            <div class="custom-control custom-checkbox" v-for="(country, i) in countryCollection()" :key="i">
                <input type="checkbox" :id="`input-country-${country.id}`" class="custom-control-input" v-model="countries" :value="country.id">
                <label :for="`input-country-${country.id}`" class="custom-control-label">{{ country.name }}</label>
            </div>
        </b-form-group>
    </form>
</template>
<script>
import formErrorsMixin from '@/mixins/form-errors'

export default {
    name: 'InventoryCreateForm',
    mixins: [ formErrorsMixin ],
    data: () => ({
        inventory: {
            name: '',
            category_id: null
        },
        countries: [],
        photo: undefined
    }),
    methods: {
        store() {
            let data = new FormData()
            let inventory = this.inventory
            const countries = this.countries
            const photo = this.photo

            this._.forOwn(inventory, (value, key) => {
                if ( ! this._.isNil(value)) {
                    data.append(key, value)
                }
            })

            if ( ! this._.isNil(photo)) {
                data.append('photo', photo)
            }

            if (countries.length > 0) {
                this._.forEach(countries, (id) => {
                    data.append('countries[]', id)
                })
            }

            this
                .axios({
                    method: 'post',
                    url: '/api/7pyn5wd/inventory/store',
                    data,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((response) => {
                    this.setErrors(response)
                    inventory = this._.get(response, 'data.data')

                    if ( ! this._.isEmpty(inventory)) {
                        this.$root.$emit('inventory-created', inventory)
                    }
                })
                .catch((error) => {
                    this.setErrors(error.response)
                })
        }
    }
}
</script>
