<template>
    <b-badge :variant="variant()">
        {{ text() }}
    </b-badge>
</template>
<script>
export default {
    name: 'EventStatus',
    props: [ 'status' ],
    methods: {
        variant() {
            const status = parseInt(this.status)
            let variant = 'info'
            switch(status) {
                case 3:
                    variant = 'success'
                    break
                case 4:
                    variant = 'danger'
                    break
            }
            return variant
        },
        text() {
            return this.eventStatusName(this.status)
        }
    }
}
</script>
