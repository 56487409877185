<template>
    <form>
        <b-form-group>
            <template v-slot:label>
                {{ $t('phrases.name') }}
                <span class="text-danger">*</span>
            </template>
            <b-form-input maxlength="255" v-model="market.name" />
            <div class="invalid-feedback d-block" v-if="error('name') !== null">
                {{ error('name') }}
            </div>
        </b-form-group>
        <b-form-group :label="$t('phrases.first_name')">
            <b-form-input maxlength="100" v-model="market.first_name" />
            <div class="invalid-feedback d-block" v-if="error('first_name') !== null">
                {{ error('first_name') }}
            </div>
        </b-form-group>
        <b-form-group :label="$t('phrases.last_name')">
            <b-form-input maxlength="100" v-model="market.last_name" />
            <div class="invalid-feedback d-block" v-if="error('last_name') !== null">
                {{ error('last_name') }}
            </div>
        </b-form-group>
        <b-form-group :label="$t('phrases.phone')">
            <b-form-input maxlength="20" v-model="market.phone" />
            <div class="invalid-feedback d-block" v-if="error('phone') !== null">
                {{ error('phone') }}
            </div>
        </b-form-group>
        <b-form-group>
            <div class="custom-control custom-checkbox">
                <input type="checkbox" id="input-publish-phone" class="custom-control-input" v-model="market.publish_phone" :true-value="1" :false-value="0">
                <label for="input-publish-phone" class="custom-control-label">{{ $t('phrases.publish_phone_on_website') }}</label>
            </div>
        </b-form-group>
        <b-form-group :label="$t('phrases.fax')">
            <b-form-input maxlength="20" v-model="market.fax" />
            <div class="invalid-feedback d-block" v-if="error('fax') !== null">
                {{ error('fax') }}
            </div>
        </b-form-group>
        <b-form-group>
            <template v-slot:label>
                {{ $t('phrases.email') }}
                <span class="text-danger">*</span>
            </template>
            <b-form-input maxlength="255" v-model="market.email" />
            <div class="invalid-feedback d-block" v-if="error('email') !== null">
                {{ error('email') }}
            </div>
        </b-form-group>
        <b-form-group :label="$t('phrases.website')">
            <b-form-input maxlength="255" v-model="market.website" />
            <div class="invalid-feedback d-block" v-if="error('website') !== null">
                {{ error('website') }}
            </div>
        </b-form-group>
        <b-form-group :label="$t('phrases.description')">
            <b-form-textarea rows="10" v-model="market.description" />
            <div class="invalid-feedback d-block" v-if="error('description') !== null">
                {{ error('description') }}
            </div>
        </b-form-group>
        <b-form-group :label="$t('phrases.address')">
            <b-form-input :value="addressLine(address, ',', ['address', 'address_2', 'city', 'state', 'zip', 'country'])" readonly />
        </b-form-group>
        <b-form-group>
            <div class="custom-control custom-checkbox">
                <input type="checkbox" id="input-publish-address" class="custom-control-input" v-model="market.publish_address" :true-value="1" :false-value="0">
                <label for="input-publish-address" class="custom-control-label">{{ $t('phrases.publish_address_on_website') }}</label>
            </div>
        </b-form-group>
        <b-form-group :label="$t('phrases.mailing_address')">
            <b-form-input :value="addressLine(mailingAddress, ',', ['address', 'address_2', 'city', 'state', 'zip', 'country'])" readonly />
        </b-form-group>
        <b-form-group>
            <div class="custom-control custom-checkbox">
                <input type="checkbox" id="input-publish-mailing-address" class="custom-control-input" v-model="market.publish_mailing_address" :true-value="1" :false-value="0">
                <label for="input-publish-mailing-address" class="custom-control-label">{{ $t('phrases.publish_mailing_address_on_website') }}</label>
            </div>
        </b-form-group>
        <b-form-group :label="$t('phrases.facebook')">
            <b-input-group prepend="https://facebook.com/">
                <b-form-input maxlength="100" v-model="market.facebook" />
            </b-input-group>
            <div class="invalid-feedback d-block" v-if="error('facebook') !== null">
                {{ error('facebook') }}
            </div>
        </b-form-group>
        <b-form-group :label="$t('phrases.twitter')">
            <b-input-group prepend="https://twitter.com/">
                <b-form-input maxlength="100" v-model="market.twitter" />
            </b-input-group>
            <div class="invalid-feedback d-block" v-if="error('twitter') !== null">
                {{ error('twitter') }}
            </div>
        </b-form-group>
        <b-form-group :label="$t('phrases.pinterest')">
            <b-input-group prepend="https://pinterest.com/">
                <b-form-input maxlength="100" v-model="market.pinterest" />
            </b-input-group>
            <div class="invalid-feedback d-block" v-if="error('pinterest') !== null">
                {{ error('pinterest') }}
            </div>
        </b-form-group>
        <b-form-group :label="$t('phrases.instagram')">
            <b-input-group prepend="https://instagram.com/">
                <b-form-input maxlength="100" v-model="market.instagram" />
            </b-input-group>
            <div class="invalid-feedback d-block" v-if="error('instagram') !== null">
                {{ error('instagram') }}
            </div>
        </b-form-group>
        <b-form-group>
            <div class="custom-control custom-checkbox">
                <input type="checkbox" id="input-promote" class="custom-control-input" v-model="market.promote" :true-value="1" :false-value="0">
                <label for="input-promote" class="custom-control-label">{{ $t('phrases.promote') }}</label>
            </div>
            <div class="custom-control custom-checkbox">
                <input type="checkbox" id="input-active" class="custom-control-input" v-model="market.active" :true-value="1" :false-value="0">
                <label for="input-active" class="custom-control-label">{{ $t('phrases.active') }}</label>
            </div>
        </b-form-group>
    </form>
</template>
<script>
import formErrorsMixin from '@/mixins/form-errors'

export default {
    name: 'MarketEditForm',
    mixins: [ formErrorsMixin ],
    props: {
        editedMarket: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        market: {
            id: null,
            name: '',
            first_name: '',
            last_name: '',
            phone: '',
            publish_phone: 0,
            fax: '',
            email: '',
            website: '',
            description: '',
            publish_address: 0,
            publish_mailing_address: 0,
            facebook: '',
            twitter: '',
            pinterest: '',
            instagram: '',
            promote: 0,
            active: 0
        }
    }),
    computed: {
        address() {
            const market = this.editedMarket
            return this.addressableAddress(market)
        },
        mailingAddress() {
            const market = this.editedMarket
            return this.addressableMailingAddress(market)
        }
    },
    methods: {
        populate() {
            const editedMarket = this.editedMarket
            const market = this.market

            if ( ! this._.isEmpty(editedMarket)) {
                this._.forOwn(editedMarket, (value, key) => {
                    if (key in market) {
                        market[key] = value
                    }
                })

                this.market = market
            }
        },
        update() {
            let market = this.market

            this
                .axios
                .put(`/api/7pyn5wd/markets/update/${market.id}`, market)
                .then((response) => {
                    this.setErrors(response)
                    market = this._.get(response, 'data.data')

                    if ( ! this._.isEmpty(market)) {
                        this.$root.$emit('market-updated', market)
                    }
                })
                .catch((error) => {
                    this.setErrors(error.response)
                })
        }
    },
    mounted() {
        this.populate()
    }
}
</script>
