<template>
    <table class="table table-bordered">
        <thead>
            <tr>
                <th class="bg-light" width="25%">{{ $t('phrases.attribute') }}</th>
                <th>{{ $t('phrases.value') }}</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="bg-light">{{ $t('phrases.id') }}</td>
                <td>{{ review.id }}</td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.subject') }}</td>
                <td>{{ review.title }}</td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.content') }}</td>
                <td v-html="nl2br(review.content)"></td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.rating') }}</td>
                <td>{{ review.rating }}</td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.active') }}</td>
                <td>
                    <bool-status :value="review.active" />
                </td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.rateable_type') }}</td>
                <td>
                    <code>{{ review.rateable_type }}</code>
                </td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.rateable_id') }}</td>
                <td>{{ review.rateable.name }}</td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.user') }}</td>
                <td>{{ fullName(review.user, false) }}</td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.created_at') }}</td>
                <td>{{ review.created_at | moment('YYYY-MM-DD HH:mm:ss') }}</td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.updated_at') }}</td>
                <td>{{ review.updated_at | moment('YYYY-MM-DD HH:mm:ss') }}</td>
            </tr>
        </tbody>
    </table>
</template>
<script>
import BoolStatus from '@/components/BoolStatus'
export default {
    name: 'ReviewView',
    components: {
        BoolStatus
    },
    props: {
        review: {
            type: Object,
            required: true
        }
    }
}
</script>
