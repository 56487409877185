export default {
    methods: {
        substanceCollection() {
            return this.$store.getters['substance_collection']
        },
        refreshSubstanceCollection() {
            this.$store.dispatch('substanceCollection')
        }
    }
}
