export default {
    state: {
        collection: [],
        meta: {}
    },
    mutations: {
        categoryCollection(state, collection) {
            state.collection = collection
        },
        categoryMeta(state, meta) {
            state.meta = meta
        }
    },
    getters: {
        'category_collection': state => state.collection,
        'category_meta': state => state.meta
    },
    actions: {
        async categoryCollection({ commit }) {
            return this
                ._vm
                .axios
                .get('/api/7pyn5wd/categories/index')
                .then((response) => {
                    commit('categoryCollection', response.data.data)
                })
                .catch(() => {
                    commit('categoryCollection', {})
                })
        },
        async categoryMeta({ commit }) {
            return this
                ._vm
                .axios
                .get('/api/7pyn5wd/categories/meta')
                .then((response) => {
                    commit('categoryMeta', response.data)
                })
                .catch(() => {
                    commit('categoryMeta', {})
                })
        }
    }
}
