<template>
    <form>
        <b-form-group>
            <template v-slot:label>
                {{ $t('phrases.name') }}
                <span class="text-danger">*</span>
            </template>
            <b-form-input maxlength="255" v-model="inventory.name" />
            <div class="invalid-feedback d-block" v-if="error('name') !== null">
                {{ error('name') }}
            </div>
        </b-form-group>
        <b-form-group>
            <template v-slot:label>
                {{ $t('phrases.category') }}
                <span class="text-danger">*</span>
            </template>
            <select class="custom-select" v-model="inventory.category_id">
                <option :value="null"></option>
                <option v-for="(category, i) in categoryCollection()" :key="i" :value="category.id">{{ category.name }}</option>
            </select>
            <div class="invalid-feedback d-block" v-if="error('category_id') !== null">
                {{ error('category_id') }}
            </div>
        </b-form-group>
        <b-form-group :label="$t('phrases.photo')">
            <b-input-group>
                <b-form-file accept="image/*" v-model="photo"></b-form-file>
                <b-input-group-append :title="$t('phrases.delete')" v-b-tooltip.hover is-text v-if="editedInventory.photo">
                    <input type="checkbox" v-model="delete_photo">
                </b-input-group-append>
            </b-input-group>
            <div class="invalid-feedback d-block" v-if="error('photo') !== null">
                {{ error('photo') }}
            </div>
            <img :src="editedInventory.photo_url" class="img-thumbnail mt-2" :alt="editedInventory.name" v-if="editedInventory.photo_url" />
        </b-form-group>
        <b-form-group :label="$t('phrases.availability')">
            <div class="custom-control custom-checkbox" v-for="(country, i) in countryCollection()" :key="i">
                <input type="checkbox" :id="`input-country-${country.id}`" class="custom-control-input" v-model="countries" :value="country.id">
                <label :for="`input-country-${country.id}`" class="custom-control-label">{{ country.name }}</label>
            </div>
        </b-form-group>
        <b-form-group :label="$t('phrases.substances')" v-if="substanceCollection().length > 0">
            <div class="input-group-list">
                <b-form-group class="mb-1" v-for="(substance, i) in substanceCollection()" :key="i">
                    <div class="custom-control custom-checkbox">
                        <input
                            type="checkbox"
                            :id="`input-substance-${substance.id}`"
                            class="custom-control-input"
                            :checked="hasSubstance(substance.id)"
                            @change="toggleSubstance(substance.id)"
                        />
                        <label :for="`input-substance-${substance.id}`" class="custom-control-label">{{ substance.name }}</label>
                    </div>
                    <div class="input-group-subform" v-if="hasSubstance(substance.id)">
                        <b-input-group>
                            <b-form-input :placeholder="$t('phrases.value')" v-model="substances[substanceIndex(substance.id)].pivot.value" />
                            <select class="custom-select" v-model="substances[substanceIndex(substance.id)].pivot.value_unit">
                                <option :value="null"> - {{ $t('phrases.unit') }} - </option>
                                <option v-for="(abbreviation, j) in unitAbbreviations()" :key="j" :value="abbreviation">{{ abbreviation }}</option>
                            </select>
                        </b-input-group>
                        <div class="invalid-feedback d-block" v-if="error('substances.' + substanceIndex(substance.id) + '.value') !== null">
                            {{ error('substances.' + substanceIndex(substance.id) + '.value') }}
                        </div>
                        <div class="invalid-feedback d-block" v-else-if="error('substances.' + substanceIndex(substance.id) + '.value_unit') !== null">
                            {{ error('substances.' + substanceIndex(substance.id) + '.value_unit') }}
                        </div>
                    </div>
                </b-form-group>
            </div>
        </b-form-group>
    </form>
</template>
<script>
import formErrorsMixin from '@/mixins/form-errors'

export default {
    name: 'InventoryEditForm',
    mixins: [ formErrorsMixin ],
    props: {
        editedInventory: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        inventory: {
            id: null,
            name: '',
            category_id: null
        },
        countries: [],
        photo: undefined,
        delete_photo: false
    }),
    computed: {
        substances() {
            const editedInventory = this.editedInventory
            return this._.get(editedInventory, 'substances', [])
        }
    },
    methods: {
        hasSubstance (id) {
            const substances = this.substances
            const substance = this._.find(substances, { id })
            return ! this._.isNil(substance)
        },
        populate() {
            const editedInventory = this.editedInventory
            const inventory = this.inventory

            if ( ! this._.isEmpty(editedInventory)) {
                const countries = this._.get(editedInventory, 'countries')

                this._.forOwn(editedInventory, (value, key) => {
                    if (key in inventory) {
                        inventory[key] = value
                    }
                })

                this.inventory = inventory

                if (countries.length > 0) {
                    this._.forEach(countries, (country) => {
                        this.countries.push(country.id)
                    })
                }
            }
        },
        substanceIndex (id) {
            return this._.findIndex(this.substances, { id })
        },
        toggleSubstance (id) {
            if (this.hasSubstance(id)) {
                this.$delete(this.substances, this.substanceIndex(id))
            } else {
                const substance = {
                    id,
                    pivot: {
                        value: '',
                        value_unit: null
                    }
                }
                const index = this.substances.length
                this.$set(this.substances, index, substance)
            }
            },
        update() {
            let data = new FormData()
            let inventory = this.inventory
            const countries = this.countries
            const substances = this.substances
            const photo = this.photo
            const deletePhoto = this.delete_photo

            data.append('_method', 'put')

            this._.forOwn(inventory, (value, key) => {
                if ( ! this._.isNil(value)) {
                    data.append(key, value)
                }
            })

            if ( ! this._.isNil(photo)) {
                data.append('photo', photo)
            }

            if (deletePhoto) {
                data.append('delete_photo', 1)
            }

            if (countries.length > 0) {
                this._.forEach(countries, (id) => {
                    data.append('countries[]', id)
                })
            }

            if (substances.length > 0) {
                this._.forEach(substances, (substance, i) => {
                    data.append('substances[' + i + '][id]', substance.id)
                    data.append('substances[' + i + '][value]', substance.pivot.value)

                    if ( ! this._.isNil(substance.pivot.value_unit)) {
                        data.append('substances[' + i + '][value_unit]', substance.pivot.value_unit)
                    }
                })
            }

            this
                .axios({
                    method: 'post',
                    url: `/api/7pyn5wd/inventory/update/${inventory.id}`,
                    data,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((response) => {
                    this.setErrors(response)
                    inventory = this._.get(response, 'data.data')

                    if ( ! this._.isEmpty(inventory)) {
                        this.$root.$emit('inventory-updated', inventory)
                    }
                })
                .catch((error) => {
                    this.setErrors(error.response)
                })
        }
    },
    mounted() {
        this.populate()
    }
}
</script>
