export default {
    state: {
        collection: []
    },
    mutations: {
        countryCollection(state, collection) {
            state.collection = collection
        }
    },
    getters: {
        'country_collection': state => state.collection
    },
    actions: {
        async countryCollection({ commit }) {
            return this
                ._vm
                .axios
                .get('/api/7pyn5wd/countries/index', {
                    params: { all: true }
                })
                .then((response) => {
                    commit('countryCollection', response.data.data)
                })
                .catch(() => {
                    commit('countryCollection', [])
                })
        }
    }
}
