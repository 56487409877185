<template>
    <form>
        <b-form-group>
            <template v-slot:label>
                {{ $t('phrases.name') }}
                <span class="text-danger">*</span>
            </template>
            <b-form-input maxlength="255" v-model="substance.name" />
            <div class="invalid-feedback d-block" v-if="error('name') !== null">
                {{ error('name') }}
            </div>
        </b-form-group>
        <b-form-group :label="$t('phrases.list_order')">
            <b-form-input type="number" v-model="substance.list_order" />
            <div class="invalid-feedback d-block" v-if="error('list_order') !== null">
                {{ error('list_order') }}
            </div>
        </b-form-group>
        <b-form-group>
            <div class="custom-control custom-checkbox">
                <input type="checkbox" id="input-active" class="custom-control-input" v-model="substance.active" :true-value="1" :false-value="0">
                <label for="input-active" class="custom-control-label">{{ $t('phrases.active') }}</label>
            </div>
        </b-form-group>
    </form>
</template>
<script>
import formErrorsMixin from '@/mixins/form-errors'

export default {
    name: 'SubstanceCreateForm',
    mixins: [ formErrorsMixin ],
    data: () => ({
        substance: {
            name: '',
            list_order: null,
            active: 0
        }
    }),
    methods: {
        store() {
            let substance = this.substance

            this
                .axios
                .post('/api/7pyn5wd/substances/store', substance)
                .then((response) => {
                    this.setErrors(response)
                    substance = this._.get(response, 'data.data')

                    if ( ! this._.isEmpty(substance)) {
                        this.$root.$emit('substance-created', substance)
                    }
                })
                .catch((error) => {
                    this.setErrors(error.response)
                })
        }
    }
}
</script>
