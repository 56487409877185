import Vue from 'vue'
import VueAxios from 'vue-axios'
import VueBootstrap from 'bootstrap-vue'
import VueLodash from 'vue-lodash'
import VueMoment from 'vue-moment'

import axios from 'axios'
import lodash from 'lodash'

// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCarrot, faEye, faLock, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faCarrot)
library.add(faEye)
library.add(faLock)
library.add(faUser)

import App from './App.vue'
import router from './router'
import store from './store'
import { i18n } from './plugins/i18n'

import addressMixin from './mixins/address'
import adminMixin from './mixins/admin'
import authMixin from './mixins/auth'
import bootstrapMixin from './mixins/bootstrap'
import categoryMixin from './mixins/category'
import cityMixin from './mixins/city'
import countryMixin from './mixins/country'
import eventMixin from './mixins/event'
import imageMixin from './mixins/image'
import inventoryMixin from './mixins/inventory'
import languageMixin from './mixins/language'
import stringMixin from './mixins/string'
import substanceMixin from './mixins/substance'
import translationMixin from './mixins/translation'
import unitMixin from './mixins/unit'

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL

Vue.use(VueAxios, axios)
Vue.use(VueBootstrap)
Vue.use(VueLodash, { lodash: lodash })
Vue.use(VueMoment)

Vue.config.productionTip = false

// --------------------------------------------------
// Mixins
// --------------------------------------------------

Vue.mixin(addressMixin)
Vue.mixin(adminMixin)
Vue.mixin(authMixin)
Vue.mixin(bootstrapMixin)
Vue.mixin(categoryMixin)
Vue.mixin(cityMixin)
Vue.mixin(countryMixin)
Vue.mixin(eventMixin)
Vue.mixin(imageMixin)
Vue.mixin(inventoryMixin)
Vue.mixin(languageMixin)
Vue.mixin(stringMixin)
Vue.mixin(substanceMixin)
Vue.mixin(translationMixin)
Vue.mixin(unitMixin)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('v-select', require('vue-select').default)

new Vue({
  router,
  i18n,
  store,
  render: h => h(App),
}).$mount('#app')
