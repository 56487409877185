<template>
    <b-badge :variant="variant()">
        {{ text() }}
    </b-badge>
</template>
<script>
export default {
    name: 'BoolStatus',
    props: [ 'value' ],
    methods: {
        variant() {
            if (parseInt(this.value) === 1) {
                return 'primary'
            } else {
                return 'danger'
            }
        },
        text() {
            if (parseInt(this.value) === 1) {
                return this.$t('phrases.yes')
            } else {
                return this.$t('phrases.no')
            }
        }
    }
}
</script>
