<template>
    <table class="table table-bordered">
        <thead>
            <tr>
                <th class="bg-light" width="25%">{{ $t('phrases.attribute') }}</th>
                <th>{{ $t('phrases.value') }}</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="bg-light">{{ $t('phrases.id') }}</td>
                <td>{{ image.id }}</td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.title') }}</td>
                <td>{{ image.title }}</td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.file') }}</td>
                <td>{{ image.file }}</td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.file_url') }}</td>
                <td>
                    <b-img :src="image.file_url" fluid></b-img>
                </td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.variations') }}</td>
                <td>
                    <pre>{{ image.variations }}</pre>
                </td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.mime_type') }}</td>
                <td>{{ image.mime }}</td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.size') }}</td>
                <td>{{ filesize(image) }} kB</td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.primary') }}</td>
                <td>
                    <bool-status :value="image.primary" />
                </td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.cover') }}</td>
                <td>
                    <bool-status :value="image.cover" />
                </td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.imageable_type') }}</td>
                <td>
                    <code>{{ image.imageable_type }}</code>
                </td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.imageable') }}</td>
                <td>
                    {{ image.imageable.name }}
                </td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.created_at') }}</td>
                <td>{{ image.created_at | moment('YYYY-MM-DD HH:mm:ss') }}</td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.updated_at') }}</td>
                <td>{{ image.updated_at | moment('YYYY-MM-DD HH:mm:ss') }}</td>
            </tr>
        </tbody>
    </table>
</template>
<script>
import BoolStatus from '@/components/BoolStatus'

export default {
    name: 'ImageView',
    components: {
        BoolStatus
    },
    props: {
        image: {
            type: Object,
            required: true
        }
    }
}
</script>
