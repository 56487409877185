<template>
    <b-container fluid>
        <h4 class="mt-4 mb-4">{{ $t('phrases.reviews') }}</h4>
        <b-row class="mb-4">
            <b-col sm="7">
                <b-button-group>
                    <b-button variant="secondary" @click="fetch()">{{ $t('phrases.refresh') }}</b-button>
                </b-button-group>
            </b-col>
            <b-col sm="5">
                <search collection="reviews" />
            </b-col>
        </b-row>
        <b-overlay :show="busy">
            <div class="bg-white shadow-sm p-4">
                <div class="table-responsive">
                    <table class="table table-hover table-bordered no-wrap">
                        <thead class="bg-light text-muted">
                            <tr>
                                <th width="10%">
                                    <sortable-link href="/reviews" collection="reviews" column="reviews.id">{{ $t('phrases.id') }}</sortable-link>
                                </th>
                                <th width="35%">
                                    <sortable-link href="/reviews" collection="reviews" column="reviews.title">{{ $t('phrases.title') }}</sortable-link>
                                </th>
                                <th width="15%">
                                    <sortable-link href="/reviews" collection="reviews" column="reviews.rating">{{ $t('phrases.rating') }}</sortable-link>
                                </th>
                                <th width="15%">
                                    <sortable-link href="/reviews" collection="reviews" column="reviews.active">{{ $t('phrases.active') }}</sortable-link>
                                </th>
                                <th class="text-right">
                                    {{ $t('phrases.actions') }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(review, i) in reviews" :key="i">
                                <td>
                                    {{ review.id }}
                                </td>
                                <td>
                                    {{ review.title }}
                                </td>
                                <td>
                                    {{ review.rating }}
                                </td>
                                <td>
                                    <bool-status :value="review.active" />
                                </td>
                                <td class="text-right">
                                    <a href="/reviews" class="ml-2" @click.prevent="activate(review)" v-if="parseInt(review.active) !== 1">{{ $t('phrases.activate') }}</a>
                                    <a href="/reviews" class="ml-2" @click.prevent="deactivate(review)" v-else>{{ $t('phrases.deactivate') }}</a>
                                    <a href="/reviews" class="ml-2" @click.prevent="show(review)">{{ $t('phrases.view') }}</a>
                                </td>
                            </tr>
                            <tr v-if="reviews.length === 0">
                                <td colspan="5" class="text-muted">
                                    {{ $t('messages.no_reviews_to_display') }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <b-row>
                    <b-col sm="6">
                        <pagination collection="reviews" :meta="meta" class="mt-3" />
                    </b-col>
                    <b-col sm="6">
                        <div class="mt-3 text-left text-sm-right">
                            {{ info() }}
                        </div>
                    </b-col>
                </b-row>
            </div>
        </b-overlay>
        <b-modal id="modal-review-view" size="lg" :title="$t('phrases.review_details')" hide-footer>
            <review-view :review="selectedReview" />
        </b-modal>
    </b-container>
</template>
<script>
import dataTableMixin from '@/mixins/data-table'
import BoolStatus from '@/components/BoolStatus'
import Pagination from '@/components/Pagination'
import ReviewView from '@/components/ReviewView'
import Search from '@/components/Search'
import SortableLink from '@/components/SortableLink'

export default {
    name: 'ReviewList',
    mixins: [ dataTableMixin ],
    components: {
        BoolStatus,
        Pagination,
        ReviewView,
        Search,
        SortableLink
    },
    data: () => ({
        selectedReview: null
    }),
    computed: {
        reviews() {
            return this._.get(this, 'resource.data', [])
        }
    },
    methods: {
        activate(review) {
            let model = this.$t('phrases.review').toLowerCase()
            let message = this.$t('messages.confirm_activate_model', { model })

            if (confirm(message)) {
                model = this.$t('phrases.review')

                this
                    .axios
                    .put(`/api/7pyn5wd/reviews/activate/${review.id}`)
                    .then(() => {
                        message = this.$t('messages.model_updated_text', { model })

                        this.toast(message, 'info')
                        this.fetch()
                    })
            }
        },
        deactivate(review) {
            let model = this.$t('phrases.review').toLowerCase()
            let message = this.$t('messages.confirm_deactivate_model', { model })

            if (confirm(message)) {
                model = this.$t('phrases.review')

                this
                    .axios
                    .put(`/api/7pyn5wd/reviews/deactivate/${review.id}`)
                    .then(() => {
                        message = this.$t('messages.model_updated_text', { model })

                        this.toast(message, 'info')
                        this.fetch()
                    })
            }
        },
        listen() {
            this.$root.$off('paginate-reviews')
            this.$root.$off('search-reviews')
            this.$root.$off('sort-reviews')
            this.$root.$off('review-updated')

            // --------------------------------------------------
            // Pagination
            // --------------------------------------------------
            this.$root.$on('paginate-reviews', (page) => {
                this.page = page
            })

            // --------------------------------------------------
            // Search
            // --------------------------------------------------
            this.$root.$on('search-reviews', (phrase) => {
                this.phrase = phrase
            })

            // --------------------------------------------------
            // Sort
            // --------------------------------------------------
            this.$root.$on('sort-reviews', ({ column, direction }) => {
                this.sort = { column, direction }
            })

            // --------------------------------------------------
            // Farm events
            // --------------------------------------------------
            this.$root.$on('review-updated', () => {
                this.$bvModal.hide('modal-review-edit')
                this.fetch()

                let model = this.$t('phrases.review')
                let message = this.$t('messages.model_updated_text', { model })

                this.toast(message, 'info')
            })
        },
        show(review) {
            this.selectedReview = review
            this.$bvModal.show('modal-review-view')
        }
    },
    mounted() {
        this.fetch()
        this.listen()
    }
}
</script>
