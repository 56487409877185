<template>
    <b-container fluid>
        <h4 class="mt-4 mb-4">{{ $t('phrases.translations') }}</h4>
        <b-row class="mb-4">
            <b-col sm="7">
                <b-button-group>
                    <b-button variant="primary" @click="create()">{{ $t('phrases.create') }}</b-button>
                    <b-button variant="secondary" @click="fetch()">{{ $t('phrases.refresh') }}</b-button>
                </b-button-group>
            </b-col>
            <b-col sm="5">
                <search collection="translations" />
            </b-col>
        </b-row>
        <b-overlay :show="busy">
            <div class="bg-white shadow-sm p-4">
                <div class="table-responsive">
                    <table class="table table-hover table-bordered no-wrap">
                        <thead class="bg-light text-muted">
                            <tr>
                                <th width="10%">
                                    <sortable-link href="/translations" collection="translations" column="translations.id">{{ $t('phrases.id') }}</sortable-link>
                                </th>
                                <th width="25%">
                                    <sortable-link href="/translations" collection="translations" column="translations.name">{{ $t('phrases.name') }}</sortable-link>
                                </th>
                                <th width="25%">
                                    <sortable-link href="/translations" collection="translations" column="translations.language_id">{{ $t('phrases.language') }}</sortable-link>
                                </th>
                                <th width="15%">
                                    <sortable-link href="/translations" collection="translations" column="translations.translatable_type">{{ $t('phrases.type') }}</sortable-link>
                                </th>
                                <th class="text-right">
                                    {{ $t('phrases.actions') }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(translation, i) in translations" :key="i">
                                <td>
                                    {{ translation.id }}
                                </td>
                                <td>
                                    {{ translation.name }}
                                </td>
                                <td>
                                    {{ translation.language.name }}
                                </td>
                                <td>
                                    <code>{{ translation.translatable_type }}</code>
                                </td>
                                <td class="text-right">
                                    <a href="/translations" @click.prevent="edit(translation)">{{ $t('phrases.edit') }}</a>
                                    <a href="/translations" class="text-danger ml-2" @click.prevent="destroy(translation)">{{ $t('phrases.delete') }}</a>
                                </td>
                            </tr>
                            <tr v-if="translations.length === 0">
                                <td colspan="5" class="text-muted">
                                    {{ $t('messages.no_translations_to_display') }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <b-row>
                    <b-col sm="6">
                        <pagination collection="translations" :meta="meta" class="mt-3" />
                    </b-col>
                    <b-col sm="6">
                        <div class="mt-3 text-left text-sm-right">
                            {{ info() }}
                        </div>
                    </b-col>
                </b-row>
            </div>
        </b-overlay>
        <b-modal id="modal-translation-create" :title="$t('phrases.create_translation')" @ok="store">
            <translation-create-form ref="form-translation-create" />
        </b-modal>
        <b-modal id="modal-translation-edit" :title="$t('phrases.edit_translation')" @ok="update">
            <translation-edit-form :edited-translation="selectedTranslation" ref="form-translation-edit" />
        </b-modal>
    </b-container>
</template>
<script>
import dataTableMixin from '@/mixins/data-table'
import Pagination from '@/components/Pagination'
import Search from '@/components/Search'
import SortableLink from '@/components/SortableLink'
import TranslationCreateForm from '@/components/TranslationCreateForm'
import TranslationEditForm from '@/components/TranslationEditForm'

export default {
    name: 'TranslationList',
    mixins: [ dataTableMixin ],
    components: {
        Pagination,
        Search,
        SortableLink,
        TranslationCreateForm,
        TranslationEditForm
    },
    data: () => ({
        selectedTranslation: null
    }),
    computed: {
        translations() {
            return this._.get(this, 'resource.data', [])
        }
    },
    methods: {
        create() {
            this.$bvModal.show('modal-translation-create')
        },
        destroy(translation) {
            let name = this._.get(translation, 'name')
            let model = this.$t('phrases.translation')
            let message = this.$t('messages.confirm_delete_name', { name })

            if (confirm(message)) {
                this
                    .axios
                    .delete(`/api/7pyn5wd/translations/${translation.id}`)
                    .then((response) => {
                        if (response.status === 204) {
                            message = this.$t('messages.model_deleted_text', { model })

                            this.toast(message, 'info')
                            this.fetch()
                        }
                    })
                    .catch(() => {
                        message = this.$t('messages.model_name_delete_error_text', { model, name })
                        this.toast(message, 'danger')
                    })
            }
        },
        edit(translation) {
            this.selectedTranslation = translation
            this.$bvModal.show('modal-translation-edit')
        },
        listen() {
            this.$root.$off('paginate-translations')
            this.$root.$off('search-translations')
            this.$root.$off('sort-translations')
            this.$root.$off('translation-created')
            this.$root.$off('translation-updated')

            // --------------------------------------------------
            // Pagination
            // --------------------------------------------------
            this.$root.$on('paginate-translations', (page) => {
                this.page = page
            })

            // --------------------------------------------------
            // Search
            // --------------------------------------------------
            this.$root.$on('search-translations', (phrase) => {
                this.phrase = phrase
            })

            // --------------------------------------------------
            // Sort
            // --------------------------------------------------
            this.$root.$on('sort-translations', ({ column, direction }) => {
                this.sort = { column, direction }
            })

            // --------------------------------------------------
            // Admin events
            // --------------------------------------------------
            this.$root.$on('translation-created', () => {
                this.$bvModal.hide('modal-translation-create')
                this.fetch()

                let model = this.$t('phrases.translation')
                let message = this.$t('messages.model_created_text', { model })

                this.toast(message, 'info')
            })

            this.$root.$on('translation-updated', () => {
                this.$bvModal.hide('modal-translation-edit')
                this.fetch()

                let model = this.$t('phrases.translation')
                let message = this.$t('messages.model_updated_text', { model })

                this.toast(message, 'info')
            })
        },
        store(event) {
            event.preventDefault()
            this.$refs['form-translation-create'].store()

            return
        },
        update(event) {
            event.preventDefault()
            this.$refs['form-translation-edit'].update()

            return
        }
    },
    mounted() {
        this.fetch()
        this.listen()
    }
}
</script>
