import router from '../../router'

export default {
    state: {
        token: null,
        admin: {}
    },
    mutations: {
        admin(state, admin) {
            state.admin = admin
        },
        login(state, token) {
            state.token = token
        },
        logout(state) {
            state.token = null
            state.admin = {}
        }
    },
    getters: {
        auth_admin: state => state.admin,
        auth_token: state => state.token
    },
    actions: {
        async admin({ commit }) {
            return this
                ._vm
                .axios
                .get('/api/7pyn5wd/admins/current')
                .then((response) => {
                    commit('admin', response.data.data)
                })
                .catch(() => {
                    commit('admin', {})
                })
        },
        async login({ commit, dispatch }, token) {
            this._vm.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

            commit('login', token)

            // Load resources
            await dispatch('admin')
            await dispatch('categoryCollection')
            await dispatch('categoryMeta')
            await dispatch('cityMeta')
            await dispatch('countryCollection')
            await dispatch('eventMeta')
            await dispatch('inventoryCollection')
            await dispatch('languageCollection')
            await dispatch('substanceCollection')
            await dispatch('translationMeta')
            await dispatch('unitMeta')

            // Redirect
            router.push({ name: 'admins' }).catch(() => {})
        },
        async logout({ commit }) {
            this.
                _vm
                .axios
                .post('/api/7pyn5wd/authentication/logout')
                .then(() => {
                    delete this._vm.axios.defaults.headers.common['Authorization']

                    commit('logout')

                    router.push({ name: 'login' })
                })
        }
    }
}
