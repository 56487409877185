export default {
    methods: {
        unitMeta() {
            return this.$store.getters['unit_meta']
        },
        unitAbbreviations() {
            return this._.get(this.unitMeta(), 'abbreviations', [])
        }
    }
}
