export default {
    methods: {
        countryCollection() {
            return this.$store.getters['country_collection']
        },
        countryName(country) {
            return this._.get(country, 'name')
        },
        refreshCountryCollection() {
            this.$store.dispatch('countryCollection')
        }
    }
}
