<template>
    <div id="app">
        <b-container fluid>
            <nav-bar v-if="isLoggedIn()" />
            <router-view />
        </b-container>
    </div>
</template>
<script>
import NavBar from './components/NavBar'
export default {
    name: 'App',
    components: {
        NavBar
    }
}
</script>
<style lang="scss">
    #app {}
</style>
