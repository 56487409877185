<template>
    <form>
        <b-form-group>
            <template v-slot:label>
                {{ $t('phrases.name') }}
                <span class="text-danger">*</span>
            </template>
            <b-form-input maxlength="255" v-model="translation.name" />
            <div class="invalid-feedback d-block" v-if="error('name') !== null">
                {{ error('name') }}
            </div>
        </b-form-group>
        <b-form-group>
            <template v-slot:label>
                {{ $t('phrases.language') }}
                <span class="text-danger">*</span>
            </template>
            <select class="custom-select" v-model="translation.language_id">
                <option :value="null"></option>
                <option v-for="(language, i) in languageCollection()" :key="i" :value="language.id">{{ language.name }}</option>
            </select>
            <div class="invalid-feedback d-block" v-if="error('language_id') !== null">
                {{ error('language_id') }}
            </div>
        </b-form-group>
        <b-form-group>
            <template v-slot:label>
                {{ $t('phrases.translatable_type') }}
                <span class="text-danger">*</span>
            </template>
            <select class="custom-select" v-model="translation.translatable_type">
                <option :value="null"></option>
                <option v-for="(name, id, i) in translatableTypes()" :key="i" :value="id">{{ name }}</option>
            </select>
            <div class="invalid-feedback d-block" v-if="error('translatable_type') !== null">
                {{ error('translatable_type') }}
            </div>
        </b-form-group>
        <b-form-group v-if="translation.translatable_type === 'c'">
            <template v-slot:label>
                {{ $t('phrases.category') }}
                <span class="text-danger">*</span>
            </template>
            <select class="custom-select" v-model="translation.translatable_id">
                <option :value="null"></option>
                <option v-for="(category, i) in categoryCollection()" :key="i" :value="category.id">{{ category.name }}</option>
            </select>
            <div class="invalid-feedback d-block" v-if="error('translatable_id') !== null">
                {{ error('translatable_id') }}
            </div>
        </b-form-group>
        <b-form-group v-else-if="translation.translatable_type === 'i'">
            <template v-slot:label>
                {{ $t('phrases.inventory') }}
                <span class="text-danger">*</span>
            </template>
            <select class="custom-select" v-model="translation.translatable_id">
                <option :value="null"></option>
                <option v-for="(inventory, i) in inventoryCollection()" :key="i" :value="inventory.id">{{ inventory.name }}</option>
            </select>
            <div class="invalid-feedback d-block" v-if="error('translatable_id') !== null">
                {{ error('translatable_id') }}
            </div>
        </b-form-group>
    </form>
</template>
<script>
import formErrorsMixin from '@/mixins/form-errors'

export default {
    name: 'TranslationCreateForm',
    mixins: [ formErrorsMixin ],
    data: () => ({
        translation: {
            name: '',
            language_id: null,
            translatable_type: null,
            translatable_id: null
        }
    }),
    methods: {
        store() {
            let translation = this.translation

            this
                .axios
                .post('/api/7pyn5wd/translations/store', translation)
                .then((response) => {
                    this.setErrors(response)
                    translation = this._.get(response, 'data.data')

                    if ( ! this._.isEmpty(translation)) {
                        this.$root.$emit('translation-created', translation)
                    }
                })
                .catch((error) => {
                    this.setErrors(error.response)
                })
        }
    },
    mounted() {
        this.refreshCategoryCollection()
        this.refreshInventoryCollection()
        this.refreshLanguageCollection()
    }
}
</script>
