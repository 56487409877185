<template>
    <b-container fluid>
        <h4 class="mt-4 mb-4">{{ $t('phrases.users') }}</h4>
        <b-row class="mb-4">
            <b-col sm="7">
                <b-button-group>
                    <b-button variant="primary" @click="create()">{{ $t('phrases.create') }}</b-button>
                    <b-button variant="secondary" @click="fetch()">{{ $t('phrases.refresh') }}</b-button>
                </b-button-group>
            </b-col>
            <b-col sm="5">
                <search collection="users" />
            </b-col>
        </b-row>
        <b-overlay :show="busy">
            <div class="bg-white shadow-sm p-4">
                <div class="table-responsive">
                    <table class="table table-hover table-bordered no-wrap">
                        <thead class="bg-light text-muted">
                            <tr>
                                <th width="10%">
                                    <sortable-link href="/users" collection="users" column="users.id">{{ $t('phrases.id') }}</sortable-link>
                                </th>
                                <th width="25%">
                                    <sortable-link href="/users" collection="users" column="users.last_name">{{ $t('phrases.name') }}</sortable-link>
                                </th>
                                <th width="25%">
                                    <sortable-link href="/users" collection="users" column="users.email">{{ $t('phrases.email') }}</sortable-link>
                                </th>
                                <th width="15%">
                                    <sortable-link href="/users" collection="users" column="users.active">{{ $t('phrases.active') }}</sortable-link>
                                </th>
                                <th class="text-right">
                                    {{ $t('phrases.actions') }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(user, i) in users" :key="i">
                                <td>
                                    {{ user.id }}
                                </td>
                                <td>
                                    {{ fullName(user, false) }}
                                </td>
                                <td>
                                    <a :href="'mailto:' + user.email" class="text-muted">{{ user.email }}</a>
                                </td>
                                <td>
                                    <bool-status :value="user.active" />
                                </td>
                                <td class="text-right">
                                    <a href="/users" @click.prevent="edit(user)">{{ $t('phrases.edit') }}</a>
                                    <a href="/users" class="ml-2" @click.prevent="show(user)">{{ $t('phrases.view') }}</a>
                                    <a href="/users" class="text-danger ml-2" @click.prevent="destroy(user)">{{ $t('phrases.delete') }}</a>
                                </td>
                            </tr>
                            <tr v-if="users.length === 0">
                                <td colspan="5" class="text-muted">
                                    {{ $t('messages.no_users_to_display') }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <b-row>
                    <b-col sm="6">
                        <pagination collection="users" :meta="meta" class="mt-3" />
                    </b-col>
                    <b-col sm="6">
                        <div class="mt-3 text-left text-sm-right">
                            {{ info() }}
                        </div>
                    </b-col>
                </b-row>
            </div>
        </b-overlay>
        <b-modal id="modal-user-create" :title="$t('phrases.create_user')" @ok="store">
            <user-create-form ref="form-user-create" />
        </b-modal>
        <b-modal id="modal-user-edit" :title="$t('phrases.edit_user')" @ok="update">
            <user-edit-form :edited-user="selectedUser" ref="form-user-edit" />
        </b-modal>
        <b-modal id="modal-user-view" size="lg" :title="$t('phrases.user_details')" hide-footer>
            <user-view :user="selectedUser" />
        </b-modal>
    </b-container>
</template>
<script>
import dataTableMixin from '@/mixins/data-table'
import BoolStatus from '@/components/BoolStatus'
import Pagination from '@/components/Pagination'
import Search from '@/components/Search'
import SortableLink from '@/components/SortableLink'
import UserCreateForm from '@/components/UserCreateForm'
import UserEditForm from '@/components/UserEditForm'
import UserView from '@/components/UserView'

export default {
    name: 'UserList',
    mixins: [ dataTableMixin ],
    components: {
        BoolStatus,
        Pagination,
        Search,
        SortableLink,
        UserCreateForm,
        UserEditForm,
        UserView
    },
    data: () => ({
        selectedUser: null
    }),
    computed: {
        users() {
            return this._.get(this, 'resource.data', [])
        }
    },
    methods: {
        create() {
            this.$bvModal.show('modal-user-create')
        },
        destroy(user) {
            let name = this.fullName(user, true)
            let model = this.$t('phrases.user')
            let message = this.$t('messages.confirm_delete_name', { name })

            if (confirm(message)) {
                this
                    .axios
                    .delete(`/api/7pyn5wd/users/${user.id}`)
                    .then((response) => {
                        if (response.status === 204) {
                            message = this.$t('messages.model_deleted_text', { model })

                            this.toast(message, 'info')
                            this.fetch()
                        }
                    })
                    .catch(() => {
                        message = this.$t('messages.model_name_delete_error_text', { model, name })
                        this.toast(message, 'danger')
                    })
            }
        },
        edit(user) {
            this.selectedUser = user
            this.$bvModal.show('modal-user-edit')
        },
        listen() {
            this.$root.$off('paginate-users')
            this.$root.$off('search-users')
            this.$root.$off('sort-users')
            this.$root.$off('user-created')
            this.$root.$off('user-updated')

            // --------------------------------------------------
            // Pagination
            // --------------------------------------------------
            this.$root.$on('paginate-users', (page) => {
                this.page = page
            })

            // --------------------------------------------------
            // Search
            // --------------------------------------------------
            this.$root.$on('search-users', (phrase) => {
                this.phrase = phrase
            })

            // --------------------------------------------------
            // Sort
            // --------------------------------------------------
            this.$root.$on('sort-users', ({ column, direction }) => {
                this.sort = { column, direction }
            })

            // --------------------------------------------------
            // Admin events
            // --------------------------------------------------
            this.$root.$on('user-created', () => {
                this.$bvModal.hide('modal-user-create')
                this.fetch()

                let model = this.$t('phrases.user')
                let message = this.$t('messages.model_created_text', { model })

                this.toast(message, 'info')
            })

            this.$root.$on('user-updated', () => {
                this.$bvModal.hide('modal-user-edit')
                this.fetch()

                let model = this.$t('phrases.user')
                let message = this.$t('messages.model_updated_text', { model })

                this.toast(message, 'info')
            })
        },
        show(user) {
            this.selectedUser = user
            this.$bvModal.show('modal-user-view')
        },
        store(event) {
            event.preventDefault()
            this.$refs['form-user-create'].store()

            return
        },
        update(event) {
            event.preventDefault()
            this.$refs['form-user-edit'].update()

            return
        }
    },
    mounted() {
        this.fetch()
        this.listen()
    }
}
</script>
