<template>
    <table class="table table-bordered">
        <thead>
            <tr>
                <th class="bg-light" width="25%">{{ $t('phrases.attribute') }}</th>
                <th>{{ $t('phrases.value') }}</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="bg-light">{{ $t('phrases.id') }}</td>
                <td>{{ inventory.id }}</td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.name') }}</td>
                <td>{{ inventory.name }}</td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.category') }}</td>
                <td>
                    <span v-if="inventory.category_id">{{ inventory.category.name }}</span>
                    <span v-else> - </span>
                </td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.photo') }}</td>
                <td>
                    <img :src="inventory.photo_url" class="img-thumbnail" :alt="inventory.name" v-if="inventory.photo_url" />
                    <span v-else> - </span>
                </td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.translation_count') }}</td>
                <td>{{ inventory.translations_count }}</td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.system') }}</td>
                <td>
                    <bool-status :value="inventory.system" />
                </td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.availability') }}</td>
                <td>
                    <span v-if="inventory.countries && inventory.countries.length">
                        <span v-for="(country, j) in inventory.countries" :key="j">
                            {{ country.name }}<span v-if="j < inventory.countries.length - 1">,</span>
                        </span>
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
</template>
<script>
import BoolStatus from '@/components/BoolStatus'
export default {
    name: 'InventoryView',
    components: { BoolStatus },
    props: {
        inventory: {
            type: Object,
            required: true
        }
    }
}
</script>
