<template>
    <div class="farm-view">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th class="bg-light" width="30%">{{ $t('phrases.attribute') }}</th>
                    <th>{{ $t('phrases.value') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="bg-light">{{ $t('phrases.id') }}</td>
                    <td>{{ farm.id }}</td>
                </tr>
                <tr>
                    <td class="bg-light">{{ $t('phrases.name') }}</td>
                    <td>{{ farm.name }}</td>
                </tr>
                <tr>
                    <td class="bg-light">{{ $t('phrases.owner') }}</td>
                    <td>{{ fullName(farm.user, false) }}</td>
                </tr>
                <tr>
                    <td class="bg-light">{{ $t('phrases.phone') }}</td>
                    <td>
                        <span v-if="farm.phone">{{ farm.phone }}</span>
                        <span v-else> - </span>
                    </td>
                </tr>
                <tr>
                    <td class="bg-light">{{ $t('phrases.publish_phone_on_website') }}</td>
                    <td>
                        <bool-status :value="farm.publish_phone" />
                    </td>
                </tr>
                <tr>
                    <td class="bg-light">{{ $t('phrases.address') }}</td>
                    <td>
                        <span v-if="address">{{ addressLine(address, ',', ['address', 'address_2', 'city', 'zip', 'state', 'country']) }}</span>
                        <span v-else> - </span>
                    </td>
                </tr>
                <tr>
                    <td class="bg-light">{{ $t('phrases.address_coordinates') }}</td>
                    <td>
                        <code v-if="address && address.latitude && address.longitude">
                            &lt;{{ address.latitude }}, {{ address.longitude }}&gt;
                        </code>
                        <span v-else> - </span>
                    </td>
                </tr>
                <tr>
                    <td class="bg-light">{{ $t('phrases.address_timezone') }}</td>
                    <td>
                        <span v-if="address && address.timezone">
                            {{ address.timezone }}
                        </span>
                        <span v-else> - </span>
                    </td>
                </tr>
                <tr>
                    <td class="bg-light">{{ $t('phrases.publish_address_on_website') }}</td>
                    <td>
                        <bool-status :value="farm.publish_address" />
                    </td>
                </tr>
                <tr>
                    <td class="bg-light">{{ $t('phrases.mailing_address') }}</td>
                    <td>
                        <span v-if="mailingAddress">{{ addressLine(mailingAddress, ',', ['address', 'address_2', 'city', 'zip', 'state', 'country']) }}</span>
                        <span v-else> - </span>
                    </td>
                </tr>
                <tr>
                    <td class="bg-light">{{ $t('phrases.mailing_address_coordinates') }}</td>
                    <td>
                        <code v-if="mailingAddress && mailingAddress.latitude && mailingAddress.longitude">
                            &lt;{{ mailingAddress.latitude }}, {{ mailingAddress.longitude }}&gt;
                        </code>
                        <span v-else> - </span>
                    </td>
                </tr>
                <tr>
                    <td class="bg-light">{{ $t('phrases.mailing_address_timezone') }}</td>
                    <td>
                        <span v-if="mailingAddress && mailingAddress.timezone">
                            {{ mailingAddress.timezone }}
                        </span>
                        <span v-else> - </span>
                    </td>
                </tr>
                <tr>
                    <td class="bg-light">{{ $t('phrases.publish_mailing_address_on_website') }}</td>
                    <td>
                        <bool-status :value="farm.publish_mailing_address" />
                    </td>
                </tr>
                <tr>
                    <td class="bg-light">{{ $t('phrases.fax') }}</td>
                    <td>
                        <span v-if="farm.fax">{{ farm.fax }}</span>
                        <span v-else> - </span>
                    </td>
                </tr>
                <tr>
                    <td class="bg-light">{{ $t('phrases.email') }}</td>
                    <td>
                        <span v-if="farm.email">{{ farm.email }}</span>
                        <span v-else> - </span>
                    </td>
                </tr>
                <tr>
                    <td class="bg-light">{{ $t('phrases.website') }}</td>
                    <td>
                        <span v-if="farm.website">{{ farm.website }}</span>
                        <span v-else> - </span>
                    </td>
                </tr>
                <tr>
                    <td class="bg-light">{{ $t('phrases.description') }}</td>
                    <td>
                        <span v-if="farm.description" v-html="nl2br(farm.description)"></span>
                        <span v-else> - </span>
                    </td>
                </tr>
                <tr>
                    <td class="bg-light">{{ $t('phrases.facebook') }}</td>
                    <td>
                        <span v-if="farm.facebook">{{ farm.facebook }}</span>
                        <span v-else> - </span>
                    </td>
                </tr>
                <tr>
                    <td class="bg-light">{{ $t('phrases.twitter') }}</td>
                    <td>
                        <span v-if="farm.twitter">{{ farm.twitter }}</span>
                        <span v-else> - </span>
                    </td>
                </tr>
                <tr>
                    <td class="bg-light">{{ $t('phrases.pinterest') }}</td>
                    <td>
                        <span v-if="farm.pinterest">{{ farm.pinterest }}</span>
                        <span v-else> - </span>
                    </td>
                </tr>
                <tr>
                    <td class="bg-light">{{ $t('phrases.instagram') }}</td>
                    <td>
                        <span v-if="farm.instagram">{{ farm.instagram }}</span>
                        <span v-else> - </span>
                    </td>
                </tr>
                <tr>
                    <td class="bg-light">{{ $t('phrases.promote') }}</td>
                    <td>
                        <bool-status :value="farm.promote" />
                    </td>
                </tr>
                <tr>
                    <td class="bg-light">{{ $t('phrases.active') }}</td>
                    <td>
                        <bool-status :value="farm.active" />
                    </td>
                </tr>
                <tr>
                    <td class="bg-light">{{ $t('phrases.deactivation_reason') }}</td>
                    <td>
                        <span v-if="farm.deactivation_reason" v-html="nl2br(farm.deactivation_reason)"></span>
                        <span v-else> - </span>
                    </td>
                </tr>
                <tr>
                    <td class="bg-light">{{ $t('phrases.deactivated_at') }}</td>
                    <td>{{ farm.deactivated_at }}</td>
                </tr>
                <tr>
                    <td class="bg-light">{{ $t('phrases.created_at') }}</td>
                    <td>{{ farm.created_at }}</td>
                </tr>
                <tr>
                    <td class="bg-light">{{ $t('phrases.updated_at') }}</td>
                    <td>{{ farm.updated_at }}</td>
                </tr>
            </tbody>
        </table>
        <div class="farm-images" v-if="images.length > 0">
            <h6 class="mt-4 mb-3">{{ $t('phrases.photos') }}</h6>
            <b-row>
                <b-col sm="4" v-for="(image, i) in images" :key="'farm-image-' + i">
                    <b-card class="mb-4" :img-src="image.file_url" v-if="image">
                        <b-card-text>
                            {{ image.title }}
                            <small class="text-muted ml-1">{{ filesize(image) }} kB</small>
                            <small class="d-block" v-if="imageIsCover(image)">{{ $t('phrases.currently_set_as_cover_image') }}</small>
                            <small class="d-block" v-if="imageIsPrimary(image)">{{ $t('phrases.currently_set_as_primary_image') }}</small>
                        </b-card-text>
                    </b-card>
                </b-col>
            </b-row>
        </div>
    </div>
</template>
<script>
import BoolStatus from '@/components/BoolStatus'

export default {
    name: 'FarmView',
    components: {
        BoolStatus
    },
    props: {
        farm: {
            type: Object,
            required: true
        }
    },
    computed: {
        address() {
            const farm = this.farm
            return this.addressableAddress(farm)
        },
        images() {
            const farm = this.farm
            return this._.get(farm, 'images', [])
        },
        mailingAddress() {
            const farm = this.farm
            return this.addressableMailingAddress(farm)
        }
    }
}
</script>
