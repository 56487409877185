export default {
    methods: {
        categoryMeta() {
            return this.$store.getters['category_meta']
        },
        categoryCollection() {
            return this.$store.getters['category_collection']
        },
        categoryTypes() {
            return this._.get(this.categoryMeta(), 'types', [])
        },
        refreshCategoryCollection() {
            this.$store.dispatch('categoryCollection')
        }
    }
}
