export default {
    methods: {
        authToken() {
            return this.$store.getters['auth_token']
        },
        isLoggedIn() {
            return ! this._.isNil(this.authToken())
        },
        async logout() {
            await this.$store.dispatch('logout')
        }
    }
}
