<template>
    <b-container fluid>
        <h4 class="mt-4 mb-4">{{ $t('phrases.messages') }}</h4>
        <b-row class="mb-4">
            <b-col sm="7">
                <b-button-group>
                    <b-button variant="secondary" @click="fetch()">{{ $t('phrases.refresh') }}</b-button>
                </b-button-group>
            </b-col>
            <b-col sm="5">
                <search collection="messages" />
            </b-col>
        </b-row>
        <b-overlay :show="busy">
            <div class="bg-white shadow-sm p-4">
                <div class="table-responsive">
                    <table class="table table-hover table-bordered no-wrap">
                        <thead class="bg-light text-muted">
                            <tr>
                                <th width="8%">
                                    <sortable-link href="/messages" collection="messages" column="messages.id">{{ $t('phrases.id') }}</sortable-link>
                                </th>
                                <th width="30%">
                                    <sortable-link href="/messages" collection="messages" column="messages.subject">{{ $t('phrases.subject') }}</sortable-link>
                                </th>
                                <th width="20%">
                                    <sortable-link href="/messages" collection="messages" column="messages.sender_id">{{ $t('phrases.sender') }}</sortable-link>
                                </th>
                                <th width="20%">
                                    <sortable-link href="/messages" collection="messages" column="messages.recipient_id">{{ $t('phrases.recipient') }}</sortable-link>
                                </th>
                                <th class="text-right">
                                    {{ $t('phrases.actions') }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(message, i) in messages" :key="i">
                                <td>
                                    {{ message.id }}
                                </td>
                                <td>
                                    {{ message.subject }}
                                </td>
                                <td>
                                    {{ fullName(message.sender, false) }}
                                </td>
                                <td>
                                    {{ fullName(message.recipient, false) }}
                                </td>
                                <td class="text-right">
                                    <a href="/messages" class="ml-2" @click.prevent="show(message)">{{ $t('phrases.view') }}</a>
                                </td>
                            </tr>
                            <tr v-if="messages.length === 0">
                                <td colspan="5" class="text-muted">
                                    {{ $t('messages.no_messages_to_display') }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <b-row>
                    <b-col sm="6">
                        <pagination collection="messages" :meta="meta" class="mt-3" />
                    </b-col>
                    <b-col sm="6">
                        <div class="mt-3 text-left text-sm-right">
                            {{ info() }}
                        </div>
                    </b-col>
                </b-row>
            </div>
        </b-overlay>
        <b-modal id="modal-message-view" size="lg" :title="$t('phrases.message_details')" hide-footer>
            <message-view :message="selectedMessage" />
        </b-modal>
    </b-container>
</template>
<script>
import dataTableMixin from '@/mixins/data-table'
import MessageView from '@/components/MessageView'
import Pagination from '@/components/Pagination'
import Search from '@/components/Search'
import SortableLink from '@/components/SortableLink'

export default {
    name: 'MessageList',
    mixins: [ dataTableMixin ],
    components: {
        MessageView,
        Pagination,
        Search,
        SortableLink
    },
    data: () => ({
        selectedMessage: null
    }),
    computed: {
        messages() {
            return this._.get(this, 'resource.data', [])
        }
    },
    methods: {
        listen() {
            this.$root.$off('paginate-messages')
            this.$root.$off('search-messages')
            this.$root.$off('sort-messages')

            // --------------------------------------------------
            // Pagination
            // --------------------------------------------------
            this.$root.$on('paginate-messages', (page) => {
                this.page = page
            })

            // --------------------------------------------------
            // Search
            // --------------------------------------------------
            this.$root.$on('search-messages', (phrase) => {
                this.phrase = phrase
            })

            // --------------------------------------------------
            // Sort
            // --------------------------------------------------
            this.$root.$on('sort-messages', ({ column, direction }) => {
                this.sort = { column, direction }
            })
        },
        show(message) {
            this.selectedMessage = message
            this.$bvModal.show('modal-message-view')
        }
    },
    mounted() {
        this.fetch()
        this.listen()
    }
}
</script>
