<template>
    <form>
        <b-form-group>
            <template v-slot:label>
                {{ $t('phrases.name') }}
                <span class="text-danger">*</span>
            </template>
            <b-form-input maxlength="255" v-model="category.name" />
            <div class="invalid-feedback d-block" v-if="error('name') !== null">
                {{ error('name') }}
            </div>
        </b-form-group>
        <b-form-group :label="$t('phrases.photo')">
            <b-form-file accept="image/*" v-model="photo"></b-form-file>
            <div class="invalid-feedback d-block" v-if="error('photo') !== null">
                {{ error('photo') }}
            </div>
        </b-form-group>
        <b-form-group>
            <template v-slot:label>
                {{ $t('phrases.type') }}
                <span class="text-danger">*</span>
            </template>
            <select class="custom-select" v-model="category.type">
                <option :value="null"></option>
                <option v-for="(name, id, i) in categoryTypes()" :key="i" :value="id">{{ name }}</option>
            </select>
            <div class="invalid-feedback d-block" v-if="error('type') !== null">
                {{ error('type') }}
            </div>
        </b-form-group>
        <b-form-group :label="$t('phrases.list_order')">
            <b-form-input type="number" v-model="category.list_order" />
            <div class="invalid-feedback d-block" v-if="error('list_order') !== null">
                {{ error('list_order') }}
            </div>
        </b-form-group>
        <b-form-group>
            <div class="custom-control custom-checkbox">
                <input type="checkbox" id="input-active" class="custom-control-input" v-model="category.active" :true-value="1" :false-value="0">
                <label for="input-active" class="custom-control-label">{{ $t('phrases.active') }}</label>
            </div>
        </b-form-group>
    </form>
</template>
<script>
import formErrorsMixin from '@/mixins/form-errors'

export default {
    name: 'CategoryCreateForm',
    mixins: [ formErrorsMixin ],
    data: () => ({
        category: {
            name: '',
            type: null,
            list_order: null,
            active: 0
        },
        photo: undefined
    }),
    methods: {
        store() {
            let data = new FormData()
            let category = this.category
            const photo = this.photo

            this._.forOwn(category, (value, key) => {
                if ( ! this._.isNil(value)) {
                    data.append(key, value)
                }
            })

            if ( ! this._.isNil(photo)) {
                data.append('photo', photo)
            }

            this
                .axios({
                    method: 'post',
                    url: '/api/7pyn5wd/categories/store',
                    data,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((response) => {
                    this.setErrors(response)
                    category = this._.get(response, 'data.data')

                    if ( ! this._.isEmpty(category)) {
                        this.$root.$emit('category-created', category)
                    }
                })
                .catch((error) => {
                    this.setErrors(error.response)
                })
        }
    }
}
</script>
