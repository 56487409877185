export default {
    auth: {
        failed: 'These credentials do not match our records.',
        otp_invalid: 'Invalid authenticator code.',
        otp_required: 'Please enter authenticator code.'
    },
    messages: {
        confirm_activate_model: 'Are you sure you want to activate this {model}?',
        confirm_deactivate_model: 'Are you sure you want to deactivate this {model}?',
        confirm_delete_model: 'Are you sure you want to delete this {model}?',
        confirm_delete_name: "Are you sure you want to delete '{name}'?",
        login_instructions: 'Please enter your email address and password to access your admin account.',
        model_created_text: '{model} has been created successfully',
        model_delete_error_text: '{model} cannot be deleted!',
        model_deleted_text: '{model} has been deleted successfully',
        model_name_delete_error_text: "{model} '{name}' cannot be deleted!",
        model_updated_text: '{model} has been updated successfully',
        no_admins_to_display: 'There are no admins to display',
        no_categories_to_display: 'There are no categories to display',
        no_cities_to_display: 'There are no cities to display',
        no_comments_to_display: 'There are no comments to display',
        no_events_to_display: 'There are no events to display',
        no_farms_to_display: 'There are no farms to display',
        no_images_to_display: 'There are no images to display',
        no_inventory_to_display: 'There is no inventory to display',
        no_languages_to_display: 'There are no languages to display',
        no_markets_to_display: 'There are no markets to display',
        no_messages_to_display: 'There are no messages to display',
        no_reviews_to_display: 'There are no reviews to display',
        no_translations_to_display: 'There are no translations to display',
        no_users_to_display: 'There are no users to display',
        pagination_meta_info: 'From {from} to {to} of {total}'
    },
    phrases: {
        actions: 'Actions',
        activate: 'Activate',
        active: 'Active',
        address: 'Address',
        address_coordinates: 'Address Coordinates',
        address_timezone: 'Address Timezone',
        admin: 'Admin',
        admin_details: 'Admin Details',
        admins: 'Admins',
        approve: 'Approve',
        ascii_name: 'ASCII Name',
        attribute: 'Attribute',
        availability: 'Availability',
        categories: 'Categories',
        category: 'Category',
        cities: 'Cities',
        city: 'City',
        city_details: 'City Details',
        clear_search: 'Clear Search',
        code: 'Code',
        comment: 'Comment',
        comment_details: 'Comment Details',
        commentable_id: 'Commentable ID',
        commentable_type: 'Commentable Type',
        comments: 'Comments',
        content: 'Content',
        coordinates: 'Coordinates',
        country: 'Country',
        cover: 'Cover',
        create: 'Create',
        create_admin: 'Create Admin',
        create_category: 'Create Category',
        create_city: 'Create City',
        create_item: 'Create Item',
        create_language: 'Create Language',
        create_translation: 'Create Translation',
        create_substance: 'Create Substance',
        create_user: 'Create User',
        created_at: 'Created At',
        currently_set_as_cover_image: 'Currently set as Cover Image',
        currently_set_as_primary_image: 'Currently set as Primary Image',
        dashboard: 'Dashboard',
        deactivate: 'Deactivate',
        deactivated_at: 'Deactivated At',
        deactivation_reason: 'Deactivation Reason',
        decline: 'Decline',
        delete: 'Delete',
        description: 'Description',
        edit: 'Edit',
        edit_admin: 'Edit Admin',
        edit_category: 'Edit Category',
        edit_city: 'Edit City',
        edit_event: 'Edit Event',
        edit_farm: 'Edit Farm',
        edit_item: 'Edit Item',
        edit_language: 'Edit Language',
        edit_market: 'Edit Market',
        edit_substance: 'Edit Substance',
        edit_translation: 'Edit Translation',
        edit_user: 'Edit User',
        email: 'Email',
        email_address: 'Email Address',
        email_verified_at: 'Email Verified At',
        end_date: 'End Date',
        end_time: 'End Time',
        event: 'Event',
        event_details: 'Event Details',
        eventable: 'Eventable',
        eventable_type: 'Eventable Type',
        events: 'Events',
        facebook: 'Facebook',
        farm: 'Farm',
        farm_details: 'Farm Details',
        farms: 'Farms',
        fax: 'Fax',
        file: 'File',
        file_url: 'File URL',
        first_name: 'First Name',
        go: 'Go',
        id: 'ID',
        image: 'Image',
        image_details: 'Image Details',
        imageable: 'Imageable',
        imageable_type: 'Imageable Type',
        images: 'Images',
        instagram: 'Instagram',
        inventory: 'Inventory',
        item: 'Item',
        item_details: 'Item Details',
        language: 'Language',
        languages: 'Languages',
        last_name: 'Last Name',
        latitude: 'Latitude',
        list_order: 'List Order',
        local_grocery: 'Local Grocery',
        login: 'Login',
        logout: 'Logout',
        longitude: 'Longitude',
        mailing_address: 'Mailing Address',
        mailing_address_coordinates: 'Mailing Address Coordinates',
        mailing_address_timezone: 'Mailing Address Timezone',
        market_details: 'Market Details',
        market: 'Market',
        markets: 'Markets',
        message: 'Message',
        message_details: 'Message Details',
        messages: 'Messages',
        mime_type: 'Mime Type',
        name: 'Name',
        name_in_native_language: 'Name in Native Language',
        no: 'No',
        operating_hours: 'Operating Hours',
        owner: 'Owner',
        password: 'Password',
        phone: 'Phone',
        photo: 'Photo',
        photos: 'Photos',
        pinterest: 'Pinterest',
        population: 'Population',
        potato: 'Potato',
        primary: 'Primary',
        promote: 'Promote',
        publish_address_on_website: 'Publish Address on Website',
        publish_mailing_address_on_website: 'Publish Mailing Address on Website',
        publish_phone_on_website: 'Publish Phone on Website',
        rateable_id: 'Rateable ID',
        rateable_type: 'Rateable Type',
        rating: 'Rating',
        read_at: 'Read At',
        recipient: 'Recipient',
        refresh: 'Refresh',
        review: 'Review',
        review_details: 'Review Details',
        reviews: 'Reviews',
        search: 'Search',
        sender: 'Sender',
        size: 'Size',
        start_date: 'Start Date',
        start_time: 'Start Time',
        state: 'State',
        status: 'Status',
        subject: 'Subject',
        substance: 'Substance',
        substances: 'Substances',
        system: 'System',
        timezone: 'Timezone',
        title: 'Title',
        translatable_id: 'Translatable ID',
        translatable_type: 'Translatable Type',
        translation: 'Translation',
        translation_count: 'Translation Count',
        translations: 'Translations',
        twitter: 'Twitter',
        type: 'Type',
        unit: 'Unit',
        updated_at: 'Updated At',
        user: 'User',
        user_details: 'User Details',
        users: 'Users',
        value: 'Value',
        variations: 'Variations',
        view: 'View',
        website: 'Website',
        yes: 'Yes',
        zip_codes: 'Zip Codes',
        zips: 'Zips'
    }
}
