export default {
    methods: {
        toast(message, variant = 'default') {
            this.$bvToast.toast(message, {
                title: this.$t('phrases.local_grocery'),
                toaster: 'b-toaster-bottom-right',
                solid: true,
                appendToast: true,
                variant: variant
            })
        }
    }
}
