<template>
    <form>
        <b-form-group>
            <template v-slot:label>
                {{ $t('phrases.name') }}
                <span class="text-danger">*</span>
            </template>
            <b-form-input maxlength="100" v-model="language.name" />
            <div class="invalid-feedback d-block" v-if="error('name') !== null">
                {{ error('name') }}
            </div>
        </b-form-group>
        <b-form-group>
            <template v-slot:label>
                {{ $t('phrases.name_in_native_language') }}
                <span class="text-danger">*</span>
            </template>
            <b-form-input maxlength="100" v-model="language.native" />
            <div class="invalid-feedback d-block" v-if="error('native') !== null">
                {{ error('native') }}
            </div>
        </b-form-group>
        <b-form-group>
            <template v-slot:label>
                {{ $t('phrases.code') }}
                <span class="text-danger">*</span>
            </template>
            <b-form-input maxlength="2" v-model="language.code" />
            <div class="invalid-feedback d-block" v-if="error('code') !== null">
                {{ error('code') }}
            </div>
        </b-form-group>
        <b-form-group>
            <div class="custom-control custom-checkbox">
                <input type="checkbox" id="input-active" class="custom-control-input" v-model="language.active" :true-value="1" :false-value="0">
                <label for="input-active" class="custom-control-label">{{ $t('phrases.active') }}</label>
            </div>
        </b-form-group>
    </form>
</template>
<script>
import formErrorsMixin from '@/mixins/form-errors'

export default {
    name: 'LanguageEditForm',
    mixins: [ formErrorsMixin ],
    props: {
        editedLanguage: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        language: {
            id: null,
            name: '',
            native: '',
            code: '',
            active: 0
        }
    }),
    methods: {
        populate() {
            const editedLanguage = this.editedLanguage
            const language = this.language

            if ( ! this._.isEmpty(editedLanguage)) {
                this._.forOwn(editedLanguage, (value, key) => {
                    if (key in language) {
                        language[key] = value
                    }
                })

                this.language = language
            }
        },
        update() {
            let language = this.language

            this
                .axios
                .put(`/api/7pyn5wd/languages/update/${language.id}`, language)
                .then((response) => {
                    this.setErrors(response)
                    language = this._.get(response, 'data.data')

                    if ( ! this._.isEmpty(language)) {
                        this.$root.$emit('language-updated', language)
                    }
                })
                .catch((error) => {
                    this.setErrors(error.response)
                })
        }
    },
    mounted() {
        this.populate()
    }
}
</script>
