<template>
    <ul class="pagination pagination-sm mb-0">
        <li class="page-item">
            <span class="page-link disabled" v-if="onFirstPage()">&laquo;</span>
            <a :href="collection" class="page-link" rel="prev" v-else @click.prevent="previousPage">&laquo;</a>
        </li>
        <li class="page-item" v-for="i in range" :key="i" :class="{'active': (currentPage === i)}">
            <span class="page-link" v-if="currentPage === i">{{ i }}</span>
            <a :href="collection" class="page-link" v-else @click.prevent="gotoPage(i)">{{ i }}</a>
        </li>
        <li class="page-item">
            <span class="page-link" v-if="!hasMorePages()">&raquo;</span>
            <a :href="collection" class="page-link" rel="next" v-else @click.prevent="nextPage">&raquo;</a>
        </li>
    </ul>
</template>
<script>
export default {
    name: 'Pagination',
    props: {
        collection: {
            type: String,
            required: false
        },
        meta: {
            type: Object,
            required: true
        }
    },
    computed: {
        currentPage() {
            return this._.get(this.meta, 'current_page', 1)
        },
        lastPage() {
            return this._.get(this.meta, 'last_page')
        },
        range() {
            let i = 1

            if (this.currentPage - 2 > 0) {
                i = this.currentPage - 2
            }

            let range = []
            let max = i + 5

            for ( ; i <= this.lastPage && i <= max; i++) {
                range.push(i)
            }

            return range
        },
        total() {
            return this._.get(this.meta, 'total')
        }
    },
    methods: {
        gotoPage(page) {
            this._.set(this.meta, 'current_page', page)
            let collection = this.collection

            if (collection) {
                let name = ['paginate', collection].join('-')
                this.$root.$emit(name, page)
            }
        },
        hasPages() {
            return this.lastPage > 1
        },
        hasMorePages() {
            return this.currentPage < this.lastPage
        },
        nextPage() {
            this.gotoPage(this.currentPage + 1)
        },
        onFirstPage() {
            return this.currentPage <= 1
        },
        previousPage() {
            this.gotoPage(this.currentPage - 1)
        }
    }
}
</script>
