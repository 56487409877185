<template>
    <b-container fluid>
        <h4 class="mt-4 mb-4">{{ $t('phrases.images') }}</h4>
        <b-row class="mb-4">
            <b-col sm="7">
                <b-button-group>
                    <b-button variant="secondary" @click="fetch()">{{ $t('phrases.refresh') }}</b-button>
                </b-button-group>
            </b-col>
            <b-col sm="5">
                <search collection="images" />
            </b-col>
        </b-row>
        <b-overlay :show="busy">
            <b-row>
                <b-col sm="4" v-for="(image, i) in images" :key="'farm-image-' + i">
                    <b-card class="mb-4" :img-src="image.file_url" v-if="image">
                        <b-card-text>
                            <b-dropdown class="float-right" variant="light" size="sm">
                                <b-dropdown-item @click.prevent="show(image)">{{ $t('phrases.view') }}</b-dropdown-item>
                                <b-dropdown-item @click.prevent="destroy(image)">{{ $t('phrases.delete') }}</b-dropdown-item>
                            </b-dropdown>
                            {{ image.title }}
                            <small class="text-muted ml-1">{{ filesize(image) }} kB</small>
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col sm="12" class="mb-4" v-if="images.length === 0">
                    {{ $t('messages.no_images_to_display') }}
                </b-col>
            </b-row>
            <b-row>
                <b-col sm="6">
                    <pagination collection="images" :meta="meta" />
                </b-col>
                <b-col sm="6">
                    <div class="text-left text-sm-right">
                        {{ info() }}
                    </div>
                </b-col>
            </b-row>
        </b-overlay>
        <b-modal id="modal-image-view" size="xl" :title="$t('phrases.image_details')" hide-footer>
            <image-view :image="selectedImage" />
        </b-modal>
    </b-container>
</template>
<script>
import dataTableMixin from '@/mixins/data-table'
import ImageView from '@/components/ImageView'
import Pagination from '@/components/Pagination'
import Search from '@/components/Search'

export default {
    name: 'ImageList',
    mixins: [ dataTableMixin ],
    components: {
        ImageView,
        Pagination,
        Search
    },
    data: () => ({
        selectedImage: null
    }),
    computed: {
        images() {
            return this._.get(this, 'resource.data', [])
        }
    },
    methods: {
        destroy(image) {
            let model = this.$t('phrases.photo').toLowerCase()
            let message = this.$t('messages.confirm_delete_model', { model })

            if (confirm(message)) {
                model = this.$t('phrases.photo')

                this
                    .axios
                    .delete(`/api/7pyn5wd/images/${image.id}`)
                    .then((response) => {
                        if (response.status === 204) {
                            message = this.$t('messages.model_deleted_text', { model })

                            this.toast(message, 'info')
                            this.fetch()
                        }
                    })
                    .catch(() => {
                        message = this.$t('messages.model_delete_error_text', { model })
                        this.toast(message, 'danger')
                    })
            }
        },
        listen() {
            this.$root.$off('paginate-images')
            this.$root.$off('search-images')
            this.$root.$off('sort-images')

            // --------------------------------------------------
            // Pagination
            // --------------------------------------------------
            this.$root.$on('paginate-images', (page) => {
                this.page = page
            })

            // --------------------------------------------------
            // Search
            // --------------------------------------------------
            this.$root.$on('search-images', (phrase) => {
                this.phrase = phrase
            })

            // --------------------------------------------------
            // Sort
            // --------------------------------------------------
            this.$root.$on('sort-images', ({ column, direction }) => {
                this.sort = { column, direction }
            })
        },
        show(image) {
            this.selectedImage = image
            this.$bvModal.show('modal-image-view')
        }
    },
    mounted() {
        this.fetch()
        this.listen()
    }
}
</script>
