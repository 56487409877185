<template>
    <b-container fluid>
        <h4 class="mt-4 mb-4">{{ $t('phrases.admins') }}</h4>
        <b-row class="mb-4">
            <b-col sm="7">
                <b-button-group>
                    <b-button variant="primary" @click="create()">{{ $t('phrases.create') }}</b-button>
                    <b-button variant="secondary" @click="fetch()">{{ $t('phrases.refresh') }}</b-button>
                </b-button-group>
            </b-col>
            <b-col sm="5">
                <search collection="admins" />
            </b-col>
        </b-row>
        <b-overlay :show="busy">
            <div class="bg-white shadow-sm p-4">
                <div class="table-responsive">
                    <table class="table table-hover table-bordered no-wrap">
                        <thead class="bg-light text-muted">
                            <tr>
                                <th width="10%">
                                    <sortable-link href="/admins" collection="admins" column="admins.id">{{ $t('phrases.id') }}</sortable-link>
                                </th>
                                <th width="25%">
                                    <sortable-link href="/admins" collection="admins" column="admins.last_name">{{ $t('phrases.name') }}</sortable-link>
                                </th>
                                <th width="25%">
                                    <sortable-link href="/admins" collection="admins" column="admins.email">{{ $t('phrases.email') }}</sortable-link>
                                </th>
                                <th width="15%">
                                    <sortable-link href="/admins" collection="admins" column="admins.active">{{ $t('phrases.active') }}</sortable-link>
                                </th>
                                <th class="text-right">
                                    {{ $t('phrases.actions') }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(admin, i) in admins" :key="i">
                                <td>
                                    {{ admin.id }}
                                </td>
                                <td>
                                    {{ fullName(admin, false) }}
                                </td>
                                <td>
                                    <a :href="'mailto:' + admin.email" class="text-muted">{{ admin.email }}</a>
                                </td>
                                <td>
                                    <bool-status :value="admin.active" />
                                </td>
                                <td class="text-right">
                                    <a href="/admins" @click.prevent="edit(admin)">{{ $t('phrases.edit') }}</a>
                                    <a href="/admins" class="ml-2" @click.prevent="show(admin)">{{ $t('phrases.view') }}</a>
                                    <a href="/admins" class="text-danger ml-2" @click.prevent="destroy(admin)">{{ $t('phrases.delete') }}</a>
                                </td>
                            </tr>
                            <tr v-if="admins.length === 0">
                                <td colspan="5" class="text-muted">
                                    {{ $t('messages.no_admins_to_display') }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <b-row>
                    <b-col sm="6">
                        <pagination collection="admins" :meta="meta" class="mt-3" />
                    </b-col>
                    <b-col sm="6">
                        <div class="mt-3 text-left text-sm-right">
                            {{ info() }}
                        </div>
                    </b-col>
                </b-row>
            </div>
        </b-overlay>
        <b-modal id="modal-admin-create" :title="$t('phrases.create_admin')" @ok="store">
            <admin-create-form ref="form-admin-create" />
        </b-modal>
        <b-modal id="modal-admin-edit" :title="$t('phrases.edit_admin')" @ok="update">
            <admin-edit-form :edited-admin="selectedAdmin" ref="form-admin-edit" />
        </b-modal>
        <b-modal id="modal-admin-view" size="lg" :title="$t('phrases.admin_details')" hide-footer>
            <admin-view :admin="selectedAdmin" />
        </b-modal>
    </b-container>
</template>
<script>
import dataTableMixin from '@/mixins/data-table'
import AdminCreateForm from '@/components/AdminCreateForm'
import AdminEditForm from '@/components/AdminEditForm'
import AdminView from '@/components/AdminView'
import BoolStatus from '@/components/BoolStatus'
import Pagination from '@/components/Pagination'
import Search from '@/components/Search'
import SortableLink from '@/components/SortableLink'

export default {
    name: 'AdminList',
    mixins: [ dataTableMixin ],
    components: {
        AdminCreateForm,
        AdminEditForm,
        AdminView,
        BoolStatus,
        Pagination,
        Search,
        SortableLink
    },
    data: () => ({
        selectedAdmin: null
    }),
    computed: {
        admins() {
            return this._.get(this, 'resource.data', [])
        }
    },
    methods: {
        create() {
            this.$bvModal.show('modal-admin-create')
        },
        destroy(admin) {
            let name = this.fullName(admin, true)
            let model = this.$t('phrases.admin')
            let message = this.$t('messages.confirm_delete_name', { name })

            if (confirm(message)) {
                this
                    .axios
                    .delete(`/api/7pyn5wd/admins/${admin.id}`)
                    .then((response) => {
                        if (response.status === 204) {
                            message = this.$t('messages.model_deleted_text', { model })

                            this.toast(message, 'info')
                            this.fetch()
                        }
                    })
                    .catch(() => {
                        message = this.$t('messages.model_name_delete_error_text', { model, name })
                        this.toast(message, 'danger')
                    })
            }
        },
        edit(admin) {
            this.selectedAdmin = admin
            this.$bvModal.show('modal-admin-edit')
        },
        listen() {
            this.$root.$off('paginate-admins')
            this.$root.$off('search-admins')
            this.$root.$off('sort-admins')
            this.$root.$off('admin-created')
            this.$root.$off('admin-updated')

            // --------------------------------------------------
            // Pagination
            // --------------------------------------------------
            this.$root.$on('paginate-admins', (page) => {
                this.page = page
            })

            // --------------------------------------------------
            // Search
            // --------------------------------------------------
            this.$root.$on('search-admins', (phrase) => {
                this.phrase = phrase
            })

            // --------------------------------------------------
            // Sort
            // --------------------------------------------------
            this.$root.$on('sort-admins', ({ column, direction }) => {
                this.sort = { column, direction }
            })

            // --------------------------------------------------
            // Admin events
            // --------------------------------------------------
            this.$root.$on('admin-created', () => {
                this.$bvModal.hide('modal-admin-create')
                this.fetch()

                let model = this.$t('phrases.admin')
                let message = this.$t('messages.model_created_text', { model })

                this.toast(message, 'info')
            })

            this.$root.$on('admin-updated', () => {
                this.$bvModal.hide('modal-admin-edit')
                this.fetch()

                let model = this.$t('phrases.admin')
                let message = this.$t('messages.model_updated_text', { model })

                this.toast(message, 'info')
            })
        },
        show(admin) {
            this.selectedAdmin = admin
            this.$bvModal.show('modal-admin-view')
        },
        store(event) {
            event.preventDefault()
            this.$refs['form-admin-create'].store()

            return
        },
        update(event) {
            event.preventDefault()
            this.$refs['form-admin-edit'].update()

            return
        }
    },
    mounted() {
        this.fetch()
        this.listen()
    }
}
</script>
