<template>
    <table class="table table-bordered">
        <thead>
            <tr>
                <th class="bg-light" width="25%">{{ $t('phrases.attribute') }}</th>
                <th>{{ $t('phrases.value') }}</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="bg-light">{{ $t('phrases.id') }}</td>
                <td>{{ admin.id }}</td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.first_name') }}</td>
                <td>{{ admin.first_name }}</td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.last_name') }}</td>
                <td>{{ admin.last_name }}</td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.email') }}</td>
                <td>
                    <a :href="'mailto:' + admin.email">{{ admin.email }}</a>
                </td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.phone') }}</td>
                <td>
                    <span v-if="admin.phone">{{ admin.phone }}</span>
                    <span v-else> - </span>
                </td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.system') }}</td>
                <td>
                    <bool-status :value="admin.system" />
                </td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.active') }}</td>
                <td>
                    <bool-status :value="admin.active" />
                </td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.created_at') }}</td>
                <td>{{ admin.created_at | moment('YYYY-MM-DD HH:mm:ss') }}</td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.updated_at') }}</td>
                <td>{{ admin.updated_at | moment('YYYY-MM-DD HH:mm:ss') }}</td>
            </tr>
        </tbody>
    </table>
</template>
<script>
import BoolStatus from '@/components/BoolStatus'

export default {
    name: 'AdminView',
    components: {
        BoolStatus
    },
    props: {
        admin: {
            type: Object,
            required: true
        }
    }
}
</script>
