<template>
    <b-navbar class="shadow-sm" toggleable="lg" variant="light">
        <b-navbar-brand>{{ $t('phrases.potato') }}</b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
                <li class="nav-item">
                    <router-link :to="{ name: 'farms' }" class="nav-link">{{ $t('phrases.farms') }}</router-link>
                </li>
                <li class="nav-item">
                    <router-link :to="{ name: 'markets' }" class="nav-link">{{ $t('phrases.markets') }}</router-link>
                </li>
                <li class="nav-item">
                    <router-link :to="{ name: 'events' }" class="nav-link">{{ $t('phrases.events') }}</router-link>
                </li>
                <li class="nav-item">
                    <router-link :to="{ name: 'images' }" class="nav-link">{{ $t('phrases.images') }}</router-link>
                </li>
                <li class="nav-item">
                    <router-link :to="{ name: 'reviews' }" class="nav-link">{{ $t('phrases.reviews') }}</router-link>
                </li>
                <li class="nav-item">
                    <router-link :to="{ name: 'comments' }" class="nav-link">{{ $t('phrases.comments') }}</router-link>
                </li>
                <li class="nav-item">
                    <router-link :to="{ name: 'messages' }" class="nav-link">{{ $t('phrases.messages') }}</router-link>
                </li>
                <b-nav-item-dropdown :text="$t('phrases.system')">
                    <router-link :to="{ name: 'admins' }" class="dropdown-item">{{ $t('phrases.admins') }}</router-link>
                    <router-link :to="{ name: 'users' }" class="dropdown-item">{{ $t('phrases.users') }}</router-link>
                    <router-link :to="{ name: 'cities' }" class="dropdown-item">{{ $t('phrases.cities') }}</router-link>
                    <router-link :to="{ name: 'languages' }" class="dropdown-item">{{ $t('phrases.languages') }}</router-link>
                    <b-dropdown-divider />
                    <router-link :to="{ name: 'categories' }" class="dropdown-item">{{ $t('phrases.categories') }}</router-link>
                    <router-link :to="{ name: 'inventory' }" class="dropdown-item">{{ $t('phrases.inventory') }}</router-link>
                    <router-link :to="{ name: 'substances' }" class="dropdown-item">{{ $t('phrases.substances') }}</router-link>
                    <router-link :to="{ name: 'translations' }" class="dropdown-item">{{ $t('phrases.translations') }}</router-link>
                </b-nav-item-dropdown>
            </b-navbar-nav>
            <b-navbar-nav class="ml-auto">
                <b-nav-item @click.prevent="logout">{{ $t('phrases.logout') }}</b-nav-item>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</template>
<script>
export default {
    name: 'NavBar',
    methods: {
        logout() {
            this.$store.dispatch('logout')
        }
    }
}
</script>
