<template>
    <form>
        <b-form-group>
            <template v-slot:label>
                {{ $t('phrases.name') }}
                <span class="text-danger">*</span>
            </template>
            <b-form-input maxlength="255" v-model="translation.name" />
            <div class="invalid-feedback d-block" v-if="error('name') !== null">
                {{ error('name') }}
            </div>
        </b-form-group>
        <b-form-group :label="$t('phrases.language')">
            <b-form-input maxlength="100" readonly :value="editedTranslation.language.name" />
        </b-form-group>
        <b-form-group :label="$t('phrases.translatable_type')">
            <b-form-input maxlength="100" readonly :value="editedTranslation.translatable_type" />
        </b-form-group>
        <b-form-group :label="$t('phrases.translatable_id')">
            <b-form-input maxlength="255" readonly :value="editedTranslation.translatable.name" />
        </b-form-group>
    </form>
</template>
<script>
import formErrorsMixin from '@/mixins/form-errors'

export default {
    name: 'TranslationEditForm',
    mixins: [ formErrorsMixin ],
    props: {
        editedTranslation: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        translation: {
            id: null,
            name: ''
        }
    }),
    methods: {
        populate() {
            const editedTranslation = this.editedTranslation
            const translation = this.translation

            if ( ! this._.isEmpty(editedTranslation)) {
                this._.forOwn(editedTranslation, (value, key) => {
                    if (key in translation) {
                        translation[key] = value
                    }
                })

                this.translation = translation
            }
        },
        update() {
            let translation = this.translation

            this
                .axios
                .put(`/api/7pyn5wd/translations/update/${translation.id}`, translation)
                .then((response) => {
                    this.setErrors(response)
                    translation = this._.get(response, 'data.data')

                    if ( ! this._.isEmpty(translation)) {
                        this.$root.$emit('translation-updated', translation)
                    }
                })
                .catch((error) => {
                    this.setErrors(error.response)
                })
        }
    },
    mounted() {
        this.populate()
    }
}
</script>
