export default {
    data: () => ({
        passwordInputType: 'password'
    }),
    methods: {
        togglePasswordInputType() {
            this.passwordInputType = (this.passwordInputType === 'password' ? 'text' : 'password')
        }
    }
}
