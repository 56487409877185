<template>
    <b-container fluid>
        <h4 class="mt-4 mb-4">{{ $t('phrases.cities') }}</h4>
        <b-row class="mb-4">
            <b-col sm="7">
                <b-button-group>
                    <b-button variant="primary" @click="create()">{{ $t('phrases.create') }}</b-button>
                    <b-button variant="secondary" @click="fetch()">{{ $t('phrases.refresh') }}</b-button>
                </b-button-group>
            </b-col>
            <b-col sm="5">
                <search collection="cities" />
            </b-col>
        </b-row>
        <b-overlay :show="busy">
            <div class="bg-white shadow-sm p-4">
                <div class="table-responsive">
                    <table class="table table-hover table-bordered no-wrap">
                        <thead class="bg-light text-muted">
                            <tr>
                                <th width="25%">
                                    <sortable-link href="/cities" collection="cities" column="cities.name">{{ $t('phrases.name') }}</sortable-link>
                                </th>
                                <th width="25%">
                                    <sortable-link href="/cities" collection="cities" column="cities.state_id">{{ $t('phrases.state') }}</sortable-link>
                                </th>
                                <th width="25%">
                                    <sortable-link href="/cities" collection="cities" column="cities.timezone">{{ $t('phrases.timezone') }}</sortable-link>
                                </th>
                                <th class="text-right">
                                    {{ $t('phrases.actions') }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(city, i) in cities" :key="i">
                                <td>
                                    {{ city.name }}
                                </td>
                                <td>
                                    {{ city.state.name }}
                                </td>
                                <td>
                                    <code v-if="city.timezone">{{ city.timezone }}</code>
                                    <span v-else> - </span>
                                </td>
                                <td class="text-right">
                                    <a href="/cities" @click.prevent="edit(city)">{{ $t('phrases.edit') }}</a>
                                    <a href="/cities" class="ml-2" @click.prevent="show(city)">{{ $t('phrases.view') }}</a>
                                    <a href="/cities" class="text-danger ml-2" @click.prevent="destroy(city)">{{ $t('phrases.delete') }}</a>
                                </td>
                            </tr>
                            <tr v-if="cities.length === 0">
                                <td colspan="4" class="text-muted">
                                    {{ $t('messages.no_cities_to_display') }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <b-row>
                    <b-col sm="6">
                        <pagination collection="cities" :meta="meta" class="mt-3" />
                    </b-col>
                    <b-col sm="6">
                        <div class="mt-3 text-left text-sm-right">
                            {{ info() }}
                        </div>
                    </b-col>
                </b-row>
            </div>
        </b-overlay>
        <b-modal id="modal-city-create" :title="$t('phrases.create_city')" @ok="store">
            <city-create-form ref="form-city-create" />
        </b-modal>
        <b-modal id="modal-city-edit" :title="$t('phrases.edit_city')" @ok="update">
            <city-edit-form :edited-city="selectedCity" ref="form-city-edit" />
        </b-modal>
        <b-modal id="modal-city-view" size="lg" :title="$t('phrases.city_details')" hide-footer>
            <city-view :city="selectedCity" />
        </b-modal>
    </b-container>
</template>
<script>
import dataTableMixin from '@/mixins/data-table'
import CityCreateForm from '@/components/CityCreateForm'
import CityEditForm from '@/components/CityEditForm'
import CityView from '@/components/CityView'
import Pagination from '@/components/Pagination'
import Search from '@/components/Search'
import SortableLink from '@/components/SortableLink'

export default {
    name: 'CityList',
    mixins: [ dataTableMixin ],
    components: {
        CityCreateForm,
        CityEditForm,
        CityView,
        Pagination,
        Search,
        SortableLink
    },
    data: () => ({
        selectedCity: null
    }),
    computed: {
        cities() {
            return this._.get(this, 'resource.data', [])
        }
    },
    methods: {
        create() {
            this.$bvModal.show('modal-city-create')
        },
        destroy(city) {
            let name = this._.get(city, 'name')
            let model = this.$t('phrases.city')
            let message = this.$t('messages.confirm_delete_name', { name })

            if (confirm(message)) {
                this
                    .axios
                    .delete(`/api/7pyn5wd/cities/${city.id}`)
                    .then((response) => {
                        if (response.status === 204) {
                            message = this.$t('messages.model_deleted_text', { model })

                            this.toast(message, 'info')
                            this.fetch()
                        }
                    })
                    .catch(() => {
                        message = this.$t('messages.model_name_delete_error_text', { model, name })
                        this.toast(message, 'danger')
                    })
            }
        },
        edit(city) {
            this.selectedCity = city
            this.$bvModal.show('modal-city-edit')
        },
        listen() {
            this.$root.$off('paginate-cities')
            this.$root.$off('search-cities')
            this.$root.$off('sort-cities')
            this.$root.$off('city-created')
            this.$root.$off('city-updated')

            // --------------------------------------------------
            // Pagination
            // --------------------------------------------------
            this.$root.$on('paginate-cities', (page) => {
                this.page = page
            })

            // --------------------------------------------------
            // Search
            // --------------------------------------------------
            this.$root.$on('search-cities', (phrase) => {
                this.phrase = phrase
            })

            // --------------------------------------------------
            // Sort
            // --------------------------------------------------
            this.$root.$on('sort-cities', ({ column, direction }) => {
                this.sort = { column, direction }
            })

            // --------------------------------------------------
            // City events
            // --------------------------------------------------
            this.$root.$on('city-created', () => {
                this.$bvModal.hide('modal-city-create')
                this.fetch()

                let model = this.$t('phrases.city')
                let message = this.$t('messages.model_created_text', { model })

                this.toast(message, 'info')
            })

            this.$root.$on('city-updated', () => {
                this.$bvModal.hide('modal-city-edit')
                this.fetch()

                let model = this.$t('phrases.city')
                let message = this.$t('messages.model_updated_text', { model })

                this.toast(message, 'info')
            })
        },
        show(city) {
            this.selectedCity = city
            this.$bvModal.show('modal-city-view')
        },
        store(event) {
            event.preventDefault()
            this.$refs['form-city-create'].store()

            return
        },
        update(event) {
            event.preventDefault()
            this.$refs['form-city-edit'].update()

            return
        }
    },
    mounted() {
        this.fetch()
        this.listen()
    }
}
</script>
