export default {
    state: {
        collection: []
    },
    mutations: {
        languageCollection(state, collection) {
            state.collection = collection
        }
    },
    getters: {
        'language_collection': state => state.collection
    },
    actions: {
        async languageCollection({ commit }) {
            return this
                ._vm
                .axios
                .get('/api/7pyn5wd/languages/index', {
                    params: { all: true, active: true }
                })
                .then((response) => {
                    commit('languageCollection', response.data.data)
                })
                .catch(() => {
                    commit('languageCollection', [])
                })
        }
    }
}
