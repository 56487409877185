export default {
    methods: {
        eventMeta() {
            return this.$store.getters['event_meta']
        },
        eventStatuses() {
            return this._.get(this.eventMeta(), 'statuses', [])
        },
        eventStatusName(id) {
            let name = null
            const statuses = this.eventStatuses()
            if (this._.isObject(statuses) && ! this._.isEmpty(statuses)) {
                this._.forOwn(statuses, (value, key) => {
                    if (key == id){
                        name = value
                    }
                } )
            }
            return name
        }
    }
}
