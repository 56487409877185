<template>
    <table class="table table-bordered">
        <thead>
            <tr>
                <th class="bg-light" width="25%">{{ $t('phrases.attribute') }}</th>
                <th>{{ $t('phrases.value') }}</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="bg-light">{{ $t('phrases.id') }}</td>
                <td>{{ message.id }}</td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.subject') }}</td>
                <td>{{ message.subject }}</td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.content') }}</td>
                <td v-html="nl2br(message.content)"></td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.sender') }}</td>
                <td>{{ fullName(message.sender, false) }}</td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.recipient') }}</td>
                <td>{{ fullName(message.recipient, false) }}</td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.read_at') }}</td>
                <td>
                    <span v-if="message.read_at">{{ message.read_at | moment('YYYY-MM-DD HH:mm:ss') }}</span>
                    <span v-else> - </span>
                </td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.created_at') }}</td>
                <td>{{ message.created_at | moment('YYYY-MM-DD HH:mm:ss') }}</td>
            </tr>
            <tr>
                <td class="bg-light">{{ $t('phrases.updated_at') }}</td>
                <td>{{ message.updated_at | moment('YYYY-MM-DD HH:mm:ss') }}</td>
            </tr>
        </tbody>
    </table>
</template>
<script>
export default {
    name: 'MessageView',
    props: {
        message: {
            type: Object,
            required: true
        }
    }
}
</script>
