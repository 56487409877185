<template>
    <div class="authentication shadow-sm">
        <logo class="mb-4" />
        <p>{{ $t('messages.login_instructions') }}</p>
        <login-form />
    </div>
</template>
<script>
import LoginForm from '@/components/LoginForm'
import Logo from '@/components/Logo'

export default {
    name: 'Login',
    components: {
        LoginForm,
        Logo
    }
}
</script>
