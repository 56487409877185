export default {
    methods: {
        cityMeta() {
            return this.$store.getters['city_meta']
        },
        timezones() {
            return this._.get(this.cityMeta(), 'timezones', [])
        }
    }
}
