<template>
    <form>
        <b-form-group>
            <template v-slot:label>
                {{ $t('phrases.name') }}
                <span class="text-danger">*</span>
            </template>
            <b-form-input maxlength="100" v-model="city.name" />
            <div class="invalid-feedback d-block" v-if="error('name') !== null">
                {{ error('name') }}
            </div>
        </b-form-group>
        <b-form-group :label="$t('phrases.zip_codes')">
            <b-form-textarea v-model="city.zips" rows="5" />
        </b-form-group>
        <b-form-group :label="$t('phrases.population')">
            <b-form-input type="number" v-model="city.population" />
            <div class="invalid-feedback d-block" v-if="error('population') !== null">
                {{ error('population') }}
            </div>
        </b-form-group>
        <b-form-group>
            <template v-slot:label>
                {{ $t('phrases.latitude') }}
                <span class="text-danger">*</span>
            </template>
            <b-form-input maxlength="10" v-model="city.latitude" />
            <div class="invalid-feedback d-block" v-if="error('latitude') !== null">
                {{ error('latitude') }}
            </div>
        </b-form-group>
        <b-form-group>
            <template v-slot:label>
                {{ $t('phrases.longitude') }}
                <span class="text-danger">*</span>
            </template>
            <b-form-input maxlength="11" v-model="city.longitude" />
            <div class="invalid-feedback d-block" v-if="error('longitude') !== null">
                {{ error('longitude') }}
            </div>
        </b-form-group>
        <b-form-group :label="$t('phrases.timezone')">
            <v-select id="input-timezone" :options="timezones()" v-model="city.timezone" />
        </b-form-group>
        <b-form-group :label="$t('phrases.country')">
            <select id="input-country" class="custom-select" v-model="country_id">
                <option :value="null"></option>
                <option v-for="(country, i) in countryCollection()" :key="i" :value="country.id">{{ country.name }}</option>
            </select>
        </b-form-group>
        <b-form-group>
            <template v-slot:label>
                {{ $t('phrases.state') }}
                <span class="text-danger">*</span>
            </template>
            <select id="input-state-id" class="custom-select" v-model="city.state_id" :disabled="states.length === 0">
                <option :value="null"></option>
                <option v-for="(state, i) in states" :key="i" :value="state.id">{{ state.name }}</option>
            </select>
            <div class="invalid-feedback d-block" v-if="error('state_id') !== null">
                {{ error('state_id') }}
            </div>
        </b-form-group>
    </form>
</template>
<script>
import formErrorsMixin from '@/mixins/form-errors'
import formCityMixin from '@/mixins/form-city'

export default {
    name: 'CityEditForm',
    mixins: [ formErrorsMixin, formCityMixin ],
    props: {
        editedCity: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        city: {
            id: null,
            name: '',
            zips: '',
            population: '',
            latitude: '',
            longitude: '',
            timezone: null,
            state_id: null
        },
        country_id: null
    }),
    methods: {
        populate() {
            const editedCity = this.editedCity
            const city = this.city

            if ( ! this._.isEmpty(editedCity)) {
                this._.forOwn(editedCity, (value, key) => {
                    if (key in city) {
                        city[key] = value
                    }
                })

                this.city = city
                this.country_id = editedCity.state.country_id
            }
        },
        update() {
            let city = this.city

            this
                .axios
                .put(`/api/7pyn5wd/cities/update/${city.id}`, city)
                .then((response) => {
                    this.setErrors(response)
                    city = this._.get(response, 'data.data')

                    if ( ! this._.isEmpty(city)) {
                        this.$root.$emit('city-updated', city)
                    }
                })
                .catch((error) => {
                    this.setErrors(error.response)
                })
        }
    },
    mounted() {
        this.populate()
    }
}
</script>
