<template>
    <a class="sortable" @click.prevent="sort">
        <slot></slot>
    </a>
</template>
<script>
export default {
    name: 'SortableLink',
    props: {
        collection: {
            type: String,
            required: true
        },
        column: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            direction: 'asc'
        }
    },
    methods: {
        listen() {
            let collection = this.collection
            let name = ['sorted', collection].join('-')

            this.$root.$on(name, ({ column }) => {
                if (column != this.column) {
                    this.$el.classList.remove('asc')
                    this.$el.classList.remove('desc')
                }
            })
        },
        sort() {
            let collection = this.collection
            let column = this.column

            if (this.direction === 'asc') {
                this.direction = 'desc'

                this.$el.classList.remove('asc')
                this.$el.classList.add('desc')
            } else {
                this.direction = 'asc'

                this.$el.classList.remove('desc')
                this.$el.classList.add('asc')
            }

            let direction = this.direction
            let name = ['sort', collection].join('-')

            this.$root.$emit(name, { column, direction })
        }
    },
    mounted() {
        this.listen()
    }
}
</script>
