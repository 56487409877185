<template>
    <form>
        <b-form-group>
            <template v-slot:label>
                {{ $t('phrases.first_name') }}
                <span class="text-danger">*</span>
            </template>
            <b-form-input maxlength="100" v-model="admin.first_name" />
            <div class="invalid-feedback d-block" v-if="error('first_name') !== null">
                {{ error('first_name') }}
            </div>
        </b-form-group>
        <b-form-group>
            <template v-slot:label>
                {{ $t('phrases.last_name') }}
                <span class="text-danger">*</span>
            </template>
            <b-form-input maxlength="100" v-model="admin.last_name" />
            <div class="invalid-feedback d-block" v-if="error('last_name') !== null">
                {{ error('last_name') }}
            </div>
        </b-form-group>
        <b-form-group>
            <template v-slot:label>
                {{ $t('phrases.email') }}
                <span class="text-danger">*</span>
            </template>
            <b-form-input maxlength="255" v-model="admin.email" />
            <div class="invalid-feedback d-block" v-if="error('email') !== null">
                {{ error('email') }}
            </div>
        </b-form-group>
        <b-form-group :label="$t('phrases.phone')">
            <b-form-input maxlength="20" v-model="admin.phone" />
            <div class="invalid-feedback d-block" v-if="error('phone') !== null">
                {{ error('phone') }}
            </div>
        </b-form-group>
        <b-form-group>
            <template v-slot:label>
                {{ $t('phrases.password') }}
                <span class="text-danger">*</span>
            </template>
            <b-form-input type="password" maxlength="40" v-model="admin.password" />
            <password :strength-meter-only="true" v-model="admin.password" />
            <div class="invalid-feedback d-block" v-if="error('password') !== null">
                {{ error('password') }}
            </div>
        </b-form-group>
        <b-form-group>
            <div class="custom-control custom-checkbox">
                <input type="checkbox" id="input-active" class="custom-control-input" v-model="admin.active" :true-value="1" :false-value="0">
                <label for="input-active" class="custom-control-label">{{ $t('phrases.active') }}</label>
            </div>
        </b-form-group>
    </form>
</template>
<script>
import formErrorsMixin from '@/mixins/form-errors'
import Password from 'vue-password-strength-meter'

export default {
    name: 'AdminCreateForm',
    mixins: [ formErrorsMixin ],
    components: { Password },
    data: () => ({
        admin: {
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            password: '',
            active: 0
        }
    }),
    methods: {
        store() {
            let admin = this.admin

            this
                .axios
                .post('/api/7pyn5wd/admins/store', admin)
                .then((response) => {
                    this.setErrors(response)
                    admin = this._.get(response, 'data.data')

                    if ( ! this._.isEmpty(admin)) {
                        this.$root.$emit('admin-created', admin)
                    }
                })
                .catch((error) => {
                    this.setErrors(error.response)
                })
        }
    }
}
</script>
